import React, {useState, useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import LoginScreen from "./LoginScreen/";
import MainApp from "./MainApp";

function App() {
  const [userData, setUserData] = useState(null);

  // window size
  function handleResize() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  useEffect(handleResize, []);

  // Debounce function to prevent to many re-renders
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  const debounceHandleResize = debounce(handleResize, 400);
  window.addEventListener('resize', debounceHandleResize);


  // Refresh page at beginning of session
  useEffect(
    () => {
      const alreadyLoaded = sessionStorage.getItem('alreadyLoaded');
      if (!alreadyLoaded) {
        sessionStorage.setItem('alreadyLoaded', true);
        window.location.reload();
      }
    }, []
  )

  // let site_url;
  // let app_url = window.location.hostname;
  // switch (window.location.hostname) {
  //     case 'localhost':
  //         site_url = 'https://augaertner.test';
  //         app_url = 'https://localhost:3000';
  //         break;
  //     case 'my.aug.onl':
  //         site_url = 'https://augaertner-ultimate.at';
  //         app_url = 'https://my.aug.onl';
  //         break;
  //     default:
  //         site_url = 'https://augaertner-ultimate.at';
  //         app_url = 'https://my.augaertner-ultimate.at';
  // }
  // console.log(window.location.hostname);
  // console.log(site_url);


  /**
   * Login screen
   */
  if (!userData) {
    return (<LoginScreen setUserData={setUserData}/>);
  }

  /**
   * Login area
   */
  return (
      <MainApp userData={userData} setUserData={setUserData}/>
  )

}

export default App;
