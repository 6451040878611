import React, {useState} from "react";

export default function MemberProfile({member}) {
  const [profileExpanded, setProfileExpanded] = useState(false);
  let profilePicStyle = {};
  if (member.profilePic !== '') {
    profilePicStyle = {backgroundImage: 'url("' + member.profilePic + '")'}
  }

  const showMail = member.gmail !== '' || member.privateMail !== '';
  const showPhone = member.phone !== '';

  return (
    <>
      <div className="member-list__row player-row"
           onClick={() => setProfileExpanded((current) => !current)}>
        <div className="member-list__profile-pic" style={profilePicStyle}/>
        <div className="member-list__member-info">
          <div className={'member-list__member-status-icon ' + member.memberType}/>
          <div className="name">
            {member.nick} #{member.jerseyNr}
          </div>
          <div className={'member-role-tags'}>
            {member.roles.map(memberRole => {
              if (memberRole !== 'member' && memberRole !== 'administrator') {
                let roleName = ''
                switch (memberRole) {
                  case 'board-member':
                    roleName = 'Vorstand';
                    break;
                  case 'cashier':
                    roleName = 'Finanzen';
                    break;
                  case 'trainer':
                    roleName = 'Trainer'
                    break;
                  default:
                    roleName = '';
                }
                return (<div key={member.nick + member.jerseyNr + memberRole}
                             className={'member-role-tag ' + memberRole}>{roleName}</div>);
              }
              return '';
            })}
          </div>
          <br/>
          <div className={'member-list__status-icons'}>
            <div className={'member-list__team-status-icon--' + member.teamStatus.value}/>
            {showMail &&
              <div className={'member-list__mail-icon'}/>
            }
            {showPhone &&
              <div className={'member-list__phone-icon'}/>
            }
            {member.clubYears > 0 &&
              <div className={'member-list__club-years'}>
                <div className={'member-list__club-years--number'}>
                  {member.clubYears}
                </div>
              </div>
            }
          </div>
        </div>

        <div className={'member-list__number'}>
          <div className={'member-list__number-inner'}>{member.jerseyNr}</div>
        </div>
      </div>

      {profileExpanded && (
        <div className={'member-list__row-expanded'}>
          {member.slogan !== false &&
            <div className={'member-list__row-expanded--slogan'}>
              {member.slogan}
            </div>
          }

          {member.fullName &&
            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Name:
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                {member.fullName}
              </div>
            </div>
          }

          {(showMail || showPhone) &&
            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Kontakt
              </div>
              {member.gmail &&
                <div className={'member-list__row-expanded-field--value'}>
                  <div className={'member-list__mail-icon'}/>
                  <a href={'mailto:' + member.gmail}>{member.gmail}</a>
                </div>
              }
              {member.privateMail &&
                <div className={'member-list__row-expanded-field--value'}>
                  <div className={'member-list__mail-icon'}/>
                  <a href={'mailto:' + member.privateMail}>{member.privateMail}</a>
                </div>
              }
              {showPhone &&
                <div className={'member-list__row-expanded-field--value'}>
                  <div className={'member-list__phone-icon'}/>
                  <a href={'tel:' + member.phone}>{member.phone}</a>
                </div>
              }
            </div>
          }

          <div className={'member-list__row-expanded-field'}>
            <div className={'member-list__row-expanded-field--label'}>
              Mitgliedschaft:
            </div>
            <div className={'member-list__row-expanded-field--value'}>
              <div className={'member-list__member-status-icon ' + member.memberType}/>
              <div className={'member-list__member-status-name ' + member.memberType}>
                {getMemberTypeTitle(member.memberType)}
              </div>
            </div>
          </div>

          {member.entryYear && (
            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Im Verein seit:
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__entry-year'}>
                  <div>{member.entryYear}</div>
                </div>

                {member.clubYears > 0 &&
                  <div className={'member-list__club-years--wrapper'}>
                    <div className={'member-list__club-years'}>
                      <div className={'member-list__club-years--number'}>
                        {member.clubYears}
                      </div>
                    </div>
                    Jahre
                  </div>
                }
              </div>
            </div>
          )}

          <div className={'member-list__row-expanded-field'}>
            <div className={'member-list__row-expanded-field--label'}>
              Team-Status:
            </div>
            <div className={'member-list__row-expanded-field--value'}>
              <div className={'member-list__team-status ' + member.teamStatus.value}>
                <div className={'member-list__team-status-icon--' + member.teamStatus.value}/>
                <div>{member.teamStatus.label}</div>
              </div>
            </div>
          </div>


          {(member.teamStatus.value === 'active' && member.trainingGroups) &&
            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Trainingsgruppen:
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__training-groups'}>
                  {member.trainingGroups.map((trainingGroup) => (
                    <div key={trainingGroup.slug} style={{backgroundColor: trainingGroup.color}}
                         className={'member-list__training-group'}>{trainingGroup.name}</div>
                  ))}
                </div>
              </div>
            </div>
          }

          {/*Trainingsgruppen?*/}
        </div>
      )}
    </>
  );


  function getMemberTypeTitle(memberTypeSlug) {
    switch (memberTypeSlug) {
      case 'honourable':
        return 'Ehrenmitglied';
      case 'supporting':
        return 'Förderndes Mitglied';
      case 'idle':
        return 'Außerordentliches Mitglied';
      default:
        return 'Ordentliches Mitglied';
    }
  }
}