import React, {useRef, useState} from "react";
import wpDataService from "../../../services/wp-data-service";
import {toast} from "react-toastify";
import {Scrollbar} from "react-scrollbars-custom";
import Loader from "../../Loader";

function ConfirmationPlayersList({allPlayers, training, type, handleConfirmationListSend}) {
  const confirmationCheckbox = useRef({});
  const [confirmationList, setConfirmationList] = useState(training.attendanceConfirmation);
  const [excludeFromPenaltyList, setExcludeFromPenaltyList] = useState(training.excludeFromPenalty);
  const [savingTrainingAttendance, setSavingTrainingAttendance] = useState(false);
  const [showCompleteTrainingOverviewPopUp, setShowCompleteTrainingOverviewPopUp] = useState(false);


  if (type === 'confirmation' && confirmationList) {
    let completeTrainingConfirmationList = confirmationList;
    Object.keys(allPlayers).forEach((index) => {
      let playerID = allPlayers[index].ID;
      if (!(playerID in confirmationList) || confirmationList[playerID] === '') {
        completeTrainingConfirmationList[playerID] = (training.attendance && playerID in training.attendance && training.attendance[playerID] !== '' ? training.attendance[playerID] : 'absent');
      }
    });
  }

  const CompleteTrainingOverviewPopUp = () => {
    if (!showCompleteTrainingOverviewPopUp) {
      return ('');
    }

    let userFeedback = {
      attending: 0,
      absent: 0,
      penalty: [],
      excludedFromPenalty: [],
    }
    Object.keys(allPlayers).forEach((index) => {
      let playerID = allPlayers[index].ID;
      let userStatus = false;

      if (typeof confirmationList !== 'object' || !(playerID in confirmationList) || confirmationList[playerID] === '') {
        userStatus = (training.attendance && playerID in training.attendance && training.attendance[playerID] !== '' ? training.attendance[playerID] : 'absent');

        if (training.attendance && playerID in training.attendance && training.attendance[playerID] !== '') {
          if (typeof excludeFromPenaltyList === 'object' ? playerID in excludeFromPenaltyList : excludeFromPenaltyList.includes(playerID)) {
            userFeedback.excludedFromPenalty.push(allPlayers[index]);
          } else {
            userFeedback.penalty.push(allPlayers[index]);
          }
        }
      } else {
        userStatus = confirmationList[playerID];

        let noAttendanceEntered = !training.attendance || ((training.attendance && !(playerID in training.attendance)) || (training.attendance && !(playerID in training.attendance) && training.attendance[playerID] === ''));
        if ((training.attendance && playerID in training.attendance && training.attendance[playerID] !== userStatus)
          || noAttendanceEntered) {
          if (typeof excludeFromPenaltyList === 'object' ? playerID in excludeFromPenaltyList : excludeFromPenaltyList.includes(playerID)) {
            userFeedback.excludedFromPenalty.push(allPlayers[index]);
          } else {
            userFeedback.penalty.push(allPlayers[index]);
          }
        }
      }

      if (userStatus) {
        userFeedback[userStatus] = userFeedback[userStatus] + 1;
      }
    });

    return (
      <div className="complete-training-confirmation-popup__wrapper">
        <Scrollbar>
        <div className="complete-training-confirmation-popup">
            <div className="complete-training-confirmation-popup__title">
              Trainingsabschluss<br/>
            </div>
            <div className="complete-training-confirmation-popup__training-name">
              Training am {training.weekday.substr(0, 2)}, {training.formattedDate.substr(0, 7)}
            </div>

            <div className="complete-training-confirmation-popup__details">
              <div className="complete-training-confirmation-popup__attendance-counts">
                Teilnehmer: {userFeedback.attending}<br/>
                Absagen: {userFeedback.absent}
              </div>

              <div className="complete-training-confirmation-popup__penalty-lists">
                {userFeedback.penalty.length > 0 &&
                  <div className="complete-training-confirmation-popup__penalty-list">
                    <div className="complete-training-confirmation-popup__penalty-list--heading">
                      Buh-Punkte:
                    </div>
                    {
                      userFeedback.penalty.map(penaltyPlayer => {
                        return (
                          <div key={penaltyPlayer.ID}>{penaltyPlayer.nickname} {penaltyPlayer.jersey_nr}</div>
                        )
                      })
                    }
                  </div>
                }

                {userFeedback.excludedFromPenalty.length > 0 &&
                  <div className="complete-training-confirmation-popup__penalty-list">
                    <div className="complete-training-confirmation-popup__penalty-list--heading">
                      Ausnahmen:
                    </div>
                    {
                      userFeedback.excludedFromPenalty.map(excludedFromPenaltyPlayer => {
                        return (
                          <div
                            key={excludedFromPenaltyPlayer.ID}>{excludedFromPenaltyPlayer.nickname} {excludedFromPenaltyPlayer.jersey_nr}</div>
                        )
                      })
                    }
                  </div>
                }
              </div>
            </div>

            <div className="aug-button" onClick={completeTraining}>Training abschließen</div>
            <div className="aug-button secondary"
                 onClick={() => setShowCompleteTrainingOverviewPopUp(false)}>Zurück
            </div>
        </div>
        </Scrollbar>
      </div>
    );
  }

  const completeTraining = () => {
    setNewTrainingAttendanceConfirmationList(true);
  }


  const setNewTrainingAttendanceConfirmationList = (completeTraining = false) => {
    setSavingTrainingAttendance(true);
    const dataService = new wpDataService();
    dataService.setTrainingAttendanceConfirmation(training.ID, confirmationList, excludeFromPenaltyList, completeTraining).then(
      (result) => {
        console.log('Training-attendance confirmation-list saved!');
        console.log(result.data);
        setSavingTrainingAttendance(false);
        handleConfirmationListSend();
        toast.success(result.data.success);

        if (completeTraining) {
          setShowCompleteTrainingOverviewPopUp(false);
        }
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
          toast.error(error.response.data.error);
        } else {
          console.error(error);
          toast.error("Trainingsteilnahme konnte nicht gespeichert werden!");
        }
        setSavingTrainingAttendance(false);
      }
    );
  }


  return (
    <>
      {savingTrainingAttendance &&
        <div className="loader-wrapper">
          <Loader/>
        </div>
      }
      {
        Object.keys(allPlayers).map((index) => {
          let playerID = allPlayers[index].ID;
          let playerClassNames = 'training-list__attendance-details-popup--player ' + allPlayers[index].gender;
          let playerTrainingStatus = '';
          if (training.attendanceData.attendingPlayersIDs.indexOf(parseInt(playerID)) > -1) {
            playerTrainingStatus = 'attending';
          } else if (training.attendanceData.absentPlayersIDs.indexOf(parseInt(playerID)) > -1) {
            playerTrainingStatus = 'absent';
          }

          let playerConfirmationStatus = Object.keys(confirmationList).includes(playerID) ? confirmationList[playerID] : '';

          let noAttendanceEntered = playerTrainingStatus === '' && playerConfirmationStatus === '';
          let playerHasPenalty = (playerTrainingStatus !== playerConfirmationStatus) && playerConfirmationStatus !== '';
          let playerIsExcludedFromPenalty = typeof excludeFromPenaltyList === 'object' ? playerID in excludeFromPenaltyList : excludeFromPenaltyList.includes(playerID);

          playerClassNames += (noAttendanceEntered ? ' required' : '');
          playerClassNames += (playerHasPenalty || noAttendanceEntered ? ' penalty' : '');
          playerClassNames += ((playerHasPenalty || noAttendanceEntered) && playerIsExcludedFromPenalty ? ' excluded-from-penalty' : '');

          return (
            <div className={playerClassNames} key={index}>
              <div className="player__attendance"
                   onClick={function () {
                     let checkbox = confirmationCheckbox.current[index];
                     if (checkbox.classList.contains('attending')) {
                       checkbox.classList.remove('attending');
                       checkbox.classList.add('absent');
                       setConfirmationList((current) => ({...current, [playerID]: 'absent'}))
                     } else if (checkbox.classList.contains('absent')) {
                       checkbox.classList.remove('absent');
                       setConfirmationList((current) => ({...current, [playerID]: ''}))
                     } else {
                       checkbox.classList.add('attending');
                       setConfirmationList((current) => ({...current, [playerID]: 'attending'}))
                     }

                     if (playerTrainingStatus !== playerConfirmationStatus) {
                       checkbox.classList.add('penalty');
                     } else {
                       checkbox.classList.remove('penalty');
                     }
                   }}>
                <div className={'player__training-state ' + playerTrainingStatus}
                     ref={(element) => {
                       confirmationCheckbox.current[index] = element
                     }}>
                  <div className="player__training-state--checkbox"/>
                </div>
                <div className="player__name">
                  {allPlayers[index].nickname} {allPlayers[index].jersey_nr}
                </div>
                <div
                  className={'player__training-confirmation player__training-state ' + playerConfirmationStatus}
                  ref={(element) => {
                    confirmationCheckbox.current[index] = element
                  }}>
                  <div className="player__training-confirmation--gradient"/>
                  <div className="player__training-state--checkbox"/>
                </div>
              </div>

              <div className="player__training-penalty"
                   onClick={function () {
                     if (!playerHasPenalty && !noAttendanceEntered) {
                       return;
                     }
                     if (playerIsExcludedFromPenalty) {
                       // convert to array, filter, convert back to object
                       setExcludeFromPenaltyList((current) => {
                         let penaltyArray = Object.keys(current);
                         return penaltyArray.filter((ID) => playerID !== ID).reduce((cur, ID) => {
                           return Object.assign(cur, {[ID]: current[ID]})
                         }, {});
                       });
                     } else {
                       setExcludeFromPenaltyList((current) => ({...current, [playerID]: true}))
                     }

                   }}>
                <div className="player__training-penalty--icon"/>
              </div>
            </div>
          );
        })
      }
      {type === 'confirmation' &&
        <>
          <button className="aug-button"
                  onClick={() => {
                    setShowCompleteTrainingOverviewPopUp(true);
                  }}
          >
            <>Training abschließen</>
          </button>

          <CompleteTrainingOverviewPopUp/>
        </>
      }
      {type !== 'confirmation' &&
        <button className="aug-button"
                onClick={() => {
                  setNewTrainingAttendanceConfirmationList();
                }}
        >
          <>Speichern</>
        </button>
      }
    </>
  )
}

export default ConfirmationPlayersList;