import React, {useState} from "react";
import AttendanceStatusButton from "../AttendanceStatusButton";
import TrainingDeadlineCountdown from "./TrainingDeadlineCountdown";

export function UpcomingTrainingShort({userID, training}) {
  const [showAttendanceInfo, setShowAttendanceInfo] = useState(false);
  let finalAttendance = Object.keys(training.attendance).some(key => key === (userID + '')) && training.attendance[userID] !== '' ? training.attendance[userID] : '';
  if (Object.keys(training.attendanceConfirmation).some(key => key === (userID + '')) && training.attendanceConfirmation[userID] !== '') {
    finalAttendance = training.attendanceConfirmation[userID];
  }


  let trainingUnitClassNames = 'training-list__training-unit';
  trainingUnitClassNames += isTrainingWithinDeadline(training) ? ' within-deadline' : '';
  trainingUnitClassNames += finalAttendance !== '' ? ' player-' + finalAttendance : ' player-not-registered';
  trainingUnitClassNames += (training.trainingStatus.value === 'closed' ? ' closed' : '');

  let trainingUnitStyle = {};
  let trainingUnitTitleStyle = {};
  if (training.trainingGroups.length > 0) {
    trainingUnitStyle.borderColor = training.trainingGroups[0].groupColor;
    trainingUnitTitleStyle.color = training.trainingGroups[0].groupColor;
  }

  const AttendanceInfoPopup = () => {
    if (!showAttendanceInfo) {
      return '';
    }

    const attendingPlayers = training.attendingPlayers;
    const attendingPlayersCount = attendingPlayers.length;

    return (
      <div className={'training-list__training-unit-popup'} style={trainingUnitStyle}
           onClick={() => {
             setShowAttendanceInfo(false)
           }}>
        <div className={'training-unit-popup__count'}>
          <span>{attendingPlayersCount}</span> Teilnehmer*Innen
        </div>
        <div className={'training-unit-popup__players'}>
        {attendingPlayers.map((player,index) => {
          return (
            <div key={index}>{player.name} <span className={'training-unit-popup__jersey-nr'}>{player.jerseyNr}</span>{index !== attendingPlayersCount.length && ','}</div>
          );
        })
        }
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={trainingUnitClassNames}
           style={trainingUnitStyle}
           onClick={() => {
             setShowAttendanceInfo((current) => !current)
           }}>

        <div className="training-list__content-column">
          <div className="training-list__title-row">
            <div
              className="training-list__date">{training.weekday.substr(0, 2)}, {training.formattedDate.substr(0, 7)}
            </div>
            <div className="training-list__title"
                 style={trainingUnitTitleStyle}>{training.data.post_title}</div>
          </div>
          <div className="training-list__time">
            <div>{training.timeFrom} - {training.timeUntil}</div>
          </div>
          {training.location.length !== 0 &&
            <div className="training-list__location">
              <div>{training.location[0].name}</div>
            </div>
          }
          <div className="training-list__groups">
            {
              training.trainingGroups.map((trainingGroup) => {
                return <div className="training-list__group"
                            key={trainingGroup.slug}>{trainingGroup.name}</div>
              })
            }
          </div>
        </div>


        <AttendanceStatusButton training={training} userID={userID}/>

        {training.trainingAnnouncement &&
          <div className="training-list__announcement">
            {training.trainingAnnouncement}
          </div>
        }

        <TrainingDeadlineCountdown training={training}/>

      </div>


      <AttendanceInfoPopup/>

    </>
  )


  function isTrainingWithinDeadline(training) {
    const today = new Date();
    const deadlineDate = new Date(training.registrationDeadline.dateString + ' ' + training.registrationDeadline.timeString);
    let deadlineCounterStartDate = new Date(training.registrationDeadline.dateString + ' ' + training.registrationDeadline.timeString);
    deadlineCounterStartDate.setDate(deadlineDate.getDate() - 1);

    if (today >= deadlineCounterStartDate && today <= deadlineDate) {
      return true;
    }
    return false;
  }
}