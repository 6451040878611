import Loader from "../../Loader";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SingleTraining from "./SingleTraining";
import wpDataService from "../../../services/wp-data-service";

function UpcomingTrainings({userRoles}) {
  const [trainingsData, setTrainingsData] = useState({});

  useEffect(() => {
    const dataService = new wpDataService();
    if (Object.keys(userRoles).length > 0 && userRoles.includes('trainer')) {
      dataService.getTrainerUpComingTrainingsData().then(
        (result) => {
          console.log('COACHES-CORNER: Received TRAINER-data');
          console.log(result.data);
          if (result.data !== 'error') {
            setTrainingsData(result.data);
          }
        },
        (error) => {
          if (error.response) {
            console.warn(error.response.data);
          } else {
            console.error(error);
          }
        }
      );
    }
  }, [userRoles])

  if (Object.keys(trainingsData).length === 0) {
    return (
      <main className="trainers__upcoming-trainings">
        <Link className="back-to-link" to={'/coaches/'}>
          {'<'} Zurück zum Coaches Corner
        </Link>
        <h2>Kommende Trainings</h2>
        <Loader/>
      </main>
    )
  }

  if (trainingsData['upcomingTrainings'].length === 0) {
    return (
      <main className="trainers__upcoming-trainings">
        <Link className="back-to-link" to={'/coaches/'}>
          {'<'} Zurück zum Coaches Corner
        </Link>
        <h2>Kommende Trainings</h2>
        Keine kommenden Trainings vorhanden..
      </main>
    )
  }

  return (
    <main className="trainers__upcoming-trainings">
      <Link className="back-to-link" to={'/coaches/'}>
        {'<'} Zurück zum Coaches Corner
      </Link>
      <h2>Kommende Trainings</h2>
      <div className="training-list training-shortlist coaches-upcoming-trainings">
        {
          trainingsData['upcomingTrainings'].map((training) => (
            <SingleTraining training={training} type={'upcoming'} previewMode={false} key={training.ID}/>
          ))
        }
      </div>
    </main>
  )
}

export default UpcomingTrainings;