// import React from "react";
// import {GoogleLogin, googleLogout, GoogleOAuthProvider} from '@react-oauth/google';

// TODO

export default function AppLogout() {
  // const logoutSuccess = (response) => {
  //   console.log(response);
  //
  //   setUserGoogleData({});
  //   setUserWPData({});
  //   setUserLoggedIn(false);
  // }

  return (''


    // <GoogleOAuthProvider clientId="758162827711-f3qsim29spegg14r53pijeh5ahtaa6s3.apps.googleusercontent.com">
    //   <GoogleLogin
    //     onSuccess={credentialResponse => {
    //       console.log(credentialResponse);
    //     }}
    //     onError={() => {
    //       console.log('Login failed');
    //     }}
    //     useOneTap
    //   />
    // </GoogleOAuthProvider>

    // <div onClick={googleLogout}>LOGOUT!!!</div>
    // <GoogleLogout
    //     clientId="758162827711-f3qsim29spegg14r53pijeh5ahtaa6s3.apps.googleusercontent.com"
    //     render={renderProps => (
    //         <div className="item-link main-link log-out" onClick={renderProps.onClick}
    //              disabled={renderProps.disabled}>
    //             Ciao, Gärtner!
    //         </div>
    //     )}
    //     onLogoutSuccess={logoutSuccess}
    // >
    // </GoogleLogout>
  );
}