import PersonalDataAppProfileOptions from "./PersonalDataAppProfileOptions";
import PersonalDataPrivacyTexts from "./PersonalDataPrivacyTexts";

export default function PersonalData({userData, setUserData}) {

  let memberStatus = 'Ordentliches Mitglied';
  if (userData.memberType === 'honourable') {
    memberStatus = 'Ehrenmitglied';
  } else if (userData.memberType === 'supporting') {
    memberStatus = 'Förderndes Mitglied';
  } else if (userData.memberType === 'idle') {
    memberStatus = 'Außerordentliches Mitglied';
  }
  const dataListRowData = [
    {
      label: 'E-Mail',
      value: userData.privateMail,
      editable: false,
    },
    {
      label: 'Telefon',
      value: userData.phone,
      editable: false,
    },
    {
      label: 'Vorname',
      value: userData.firstName,
      editable: false,
    },
    {
      label: 'Nachname',
      value: userData.lastName,
      editable: false,
    },
    {
      label: 'Geburtstag',
      value: userData.birthday,
      editable: false,
    },
    {
      label: 'Geschlecht',
      value: userData.gender,
      editable: false,
    },
    {
      label: 'Nick',
      value: userData.nick,
      editable: false,
    },
    {
      label: 'Nummer',
      value: userData.jerseyNr,
      editable: false,
    },
    {
      label: 'Augärtner-Login',
      value: userData.email,
      editable: false,
    },
    {
      label: 'Vereinsmitglied seit',
      value: userData.entryYear,
      editable: false,
    },
    {
      label: 'Art der Mitgliedschaft',
      value: memberStatus,
      editable: false,
    },
  ];

  const DataListRow = ({label, value, editable = false}) => (
    <div className={'personal-data__data-list-row'}>
      <div className={'personal-data__data-list--label'}>
        {label}
      </div>
      <div className={'personal-data__data-list--value'}>
        {value}
      </div>
      {editable === true &&
        <div className={'personal-data__data-list--edit-button'}/>
      }
    </div>
  )

  const DataList = () => {
    return (
      <div className={'personal-data__data-list'}>
        {dataListRowData.map((dataListRow, index) => (
          <DataListRow key={index} label={dataListRow.label} value={dataListRow.value} editable={dataListRow.editable}/>
        ))}
      </div>
    )
  }

  const appProfileOptionsData = {
    userData: userData,
    setUserData: setUserData
  }

  return (
    <main className="personal-data">
      <h2 id={'personal-data'}>Persönliche Daten</h2>
      <p>
        Hier kannst du deine Daten ändern und festlegen, welche dieser Daten in der Mitgliederliste angezeigt werden
        sollen.
      </p>

      <ul>
        <li>
          <a href={'#app-profile'}>App-Profil Einstellungen vornehmen</a>
        </li>
        <li>
          <a href={'#personal-data-info'}>Persönliche Daten einsehen & ändern</a>
        </li>
        <li>
          <a href={'#profile-pic'}>Dein Profilbild ändern</a>
        </li>
        <li>
          <a href={'#privacy-texts'}>Unsere Datenschutz-Bestimmungen einsehen</a>
        </li>
      </ul>

      <br/><br/>
      <h3 id={'app-profile'}>Dein App-Profil</h3>
      <PersonalDataAppProfileOptions {...appProfileOptionsData} />

      <br/><br/>
      <h3 id={'profile-pic'}>Profilbild</h3>
      <p>
        <img width={96} height={96} src={userData.picture} alt={'profile pic'}/><br/>
        Du kannst dein Profilbild in deinen Google-Account-Optionen einrichten und ändern.
        <br/>>> Deinen Google-Account findest du unter <a href={'https://myaccount.google.com/'} target={'_blank'}
                                                          rel={'noreferrer'}>https://myaccount.google.com/</a>
      </p>


      <br/><br/>
      <h3 id={'personal-data-info'}>Deine Daten</h3>
      <p>
        Wir haben folgende Daten zu dir gespeichert:<br/>
        <span className={'personal-data__label-description'}>
          Nur unser Vorstand & unsere Trainer:innen haben Zugang zu deinen Kontaktdaten und Infos.
        </span>
      </p>
      <DataList/>

      <br/><br/>
      <h3 id={'privacy-texts'}>Datenschutz</h3>
      <PersonalDataPrivacyTexts/>
    </main>
  );
}