import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Loader from "../../Loader";
import wpDataService from "../../../services/wp-data-service";
import SingleTraining from "./SingleTraining";

function CoachesCorner({userRoles}) {
  const [closedTrainingsPastDeadline, setClosedTrainingsPastDeadline] = useState([]);
  const [isLoadingClosedTrainingsPastDeadline, setIsLoadingClosedTrainingsPastDeadline] = useState(true);
  const [nextUpcomingTrainingData, setNextUpcomingTrainingData] = useState({});
  const [isLoadingNextUpcomingTrainingData, setIsLoadingNextUpcomingTrainingData] = useState(true);

  const loadTrainingsData = () => {
    const dataService = new wpDataService();
    if (Object.keys(userRoles).length > 0 && userRoles.includes('trainer')) {

      dataService.getClosedTrainingsPastDeadline().then(
        (result) => {
          console.log('COACHES-CORNER: Received TRAININGS for CONFIRMATION');
          console.log(result.data);
          if (result.data !== 'error') {
            setClosedTrainingsPastDeadline(result.data);
          }
          setIsLoadingClosedTrainingsPastDeadline(false);
        },
        (error) => {
          if (error.response) {
            console.warn(error.response.data);
          } else {
            console.error(error);
          }
          setIsLoadingClosedTrainingsPastDeadline(false);
        }
      );

      dataService.getCoachesNextUpcomingTraining().then(
        (result) => {
          console.log('COACHES-CORNER: Received NEXT UPCOMING TRAINING data');
          console.log(result.data);
          if (result.data !== 'error') {
            setNextUpcomingTrainingData(result.data);
          }
          setIsLoadingNextUpcomingTrainingData(false);
        },
        (error) => {
          if (error.response) {
            console.warn(error.response.data);
          } else {
            console.error(error);
          }
          setIsLoadingNextUpcomingTrainingData(false);
        }
      );

    }
  };
  useEffect(loadTrainingsData, [userRoles]);


  const ConfirmationList = () => {
    if (isLoadingClosedTrainingsPastDeadline) {
      return (
        <div className={'coaches-corner__confirmation-list'}>
          <h3>Trainingsabschluss</h3>
          <div className={'coaches-corner__confirmation-list--loading-info-box'}>
            <div className={'content'}>Suche nach Trainings.. <Loader/></div>
          </div>
        </div>
      )
    }
    if (!closedTrainingsPastDeadline || closedTrainingsPastDeadline.length === 0) {
      return null;
    }

    let training = closedTrainingsPastDeadline[0];
    return (
      <div>
        <h3>Trainingsabschluss</h3>
        <div
          className={'training-list training-shortlist coaches-upcoming-trainings coaches-confirmation-list'}>
          <SingleTraining training={training} type={'confirmation'} reloadTrainingData={loadTrainingsData}/>
        </div>
      </div>
    )
  }

  const UpcomingTrainingPreview = () => {
    if (isLoadingNextUpcomingTrainingData) {
      return (
        <div>
          <h3>Trainingsvorschau</h3>

          <Loader/>

          <Link className="aug-button secondary chevron-right wide" to="/coaches/upcoming-trainings">
            Alle kommenden Trainings
          </Link>
        </div>
      );
    }
    if (!nextUpcomingTrainingData || Object.keys(nextUpcomingTrainingData).length === 0) {
      return (
        <div>
          <h3>Trainingsvorschau</h3>

          <Loader/>

          <Link className="aug-button secondary chevron-right wide" to="/coaches/upcoming-trainings">
            Alle kommenden Trainings
          </Link>
        </div>
      );
    }
    if (nextUpcomingTrainingData.length === 0) {
      return (
        <div>
          <h3>Trainingsvorschau</h3>

          <Link className="aug-button secondary chevron-right wide" to="/coaches/upcoming-trainings">
            Alle kommenden Trainings
          </Link>
        </div>
      );
    }

    return (
      <div>
        <h3>Trainingsvorschau</h3>

        <div className={'training-list training-shortlist coaches-upcoming-trainings'}>
          <SingleTraining training={nextUpcomingTrainingData} type={'preview'} reloadTrainingData={loadTrainingsData}/>
        </div>
        <Link className="aug-button secondary chevron-right wide" to="/coaches/upcoming-trainings">
          Alle kommenden Trainings
        </Link>
      </div>
    )
  }

  const PastTrainings = () => {
    return (
      <div>
        <h3>Vergangene Trainings</h3>
        <Link className="aug-button secondary chevron-right wide" to="/coaches/past-trainings">
          Vergangene Trainings
        </Link>
      </div>
    )
  }


  return (
    <main className="coaches-corner">
      <h2>Coaches Corner</h2>
      <ConfirmationList/>
      <UpcomingTrainingPreview/>
      <PastTrainings/>
    </main>
  )

}

export default CoachesCorner;