import React, {useState} from "react";
import ConfirmationPlayersList from "./ConfirmationPlayersList";
import {Scrollbar} from "react-scrollbars-custom";
import wpDataService from "../../../services/wp-data-service";
import {toast} from "react-toastify";
import Loader from "../../Loader";

// type: type of training (upcoming, past, confirmation,...)
// previewMode: show training in preview or not
function SingleTraining({training, type, previewMode = true, reloadTrainingData}) {
  const [isEditTrainingPopupActive, setIsEditTrainingPopupActive] = useState(false);
  const [isCompleteTrainingPopupActive, setIsCompleteTrainingPopupActive] = useState(false);
  const [showAttendanceDetails, setShowAttendanceDetails] = useState(previewMode);
  const [activeAttendanceListType, setActiveAttendanceListType] = useState('attending');
  const [savingTrainingDetails, setSavingTrainingDetails] = useState(false);

  let originalTrainingDetails = {
    'trainingStatus': training.trainingStatus.value,
    'trainingAnnouncement': training.trainingAnnouncement,
    'trainingNotes': training.trainingNotes,
    'trainingDeadline': training.trainingDeadline,
  };
  const [trainingDetails, setTrainingDetails] = useState(originalTrainingDetails);

  let allPlayers = training.attendanceData.allTrainingPlayersSorted
  let trainingUnitClassNames = 'training-list__training-unit' + (showAttendanceDetails ? ' active' : '') + ' ' + trainingDetails.trainingStatus;

  let trainingUnitStyle = {};
  let trainingUnitTitleStyle = {};

  if (training.trainingGroups.length > 0) {
    trainingUnitStyle.borderColor = training.trainingGroups[0].groupColor;
    trainingUnitTitleStyle.color = training.trainingGroups[0].groupColor;
  }


  const handleConfirmationListSend = () => {
    setIsEditTrainingPopupActive(false);
    reloadTrainingData();
  }

  const setNewTrainingDetails = () => {
    setSavingTrainingDetails(true);
    const dataService = new wpDataService();
    dataService.setTrainingDetails(training.ID, trainingDetails.trainingStatus, trainingDetails.trainingAnnouncement, trainingDetails.trainingNotes).then(
      (result) => {
        console.log('Training-details saved!');
        console.log(result.data);
        setSavingTrainingDetails(false);
        // handleConfirmationListSend();
        toast.success(result.data.success);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
          toast.error(error.response.data.error);
        } else {
          console.error(error);
          toast.error("Trainings-Details konnten nicht gespeichert werden!");
        }
        setSavingTrainingDetails(false);
      }
    );
  }

  const handleAttendanceListTypeClick = (attendanceListType) => {
    setActiveAttendanceListType(() => attendanceListType);
  }

  let trainingListPlayers = training.attendanceData.finalAttendance[activeAttendanceListType];

  return (
    <>
      <div className={trainingUnitClassNames} style={trainingUnitStyle}>
        <div className="training-list__content-column">
          <div className="training-list__title-row">
            <div
              className="training-list__date">{training.weekday.substr(0, 2)}, {training.formattedDate.substr(0, 7)}
            </div>
            <div className="training-list__title" style={trainingUnitTitleStyle}>{training.data.post_title}</div>
          </div>

          <div className="training-list__time">
            <div>{training.timeFrom} - {training.timeUntil}</div>
          </div>

          {training.location.length !== 0 &&
            <div className="training-list__location">
              <div>{training.location[0].name}</div>
            </div>
          }

          <div className="training-list__attendance-info">

            <div className="attendance-info__left-column">
              <div className="attendance-info attending">
                <div className="attending-players-count">
                  {training.attendanceData.finalAttendance.attendingCount}
                </div>

                <div className="attendance-info__title">Zusagen</div>

                <div className="attendance-info__gender-overview">
                  {training.attendanceData.finalAttendance.attendingGenderCounts.f > 0 &&
                    <div className="gender f">
                      {training.attendanceData.finalAttendance.attendingGenderCounts.f}
                    </div>
                  }
                  {training.attendanceData.finalAttendance.attendingGenderCounts.m > 0 &&
                    <div className="gender m">
                      {training.attendanceData.finalAttendance.attendingGenderCounts.m}
                    </div>
                  }
                  {training.attendanceData.finalAttendance.attendingGenderCounts.x > 0 &&
                    <div className="gender x">
                      {training.attendanceData.finalAttendance.attendingGenderCounts.x}
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className="attendance-info__right-column">
              <div className="attendance-infos">
                <div className="attendance-info absent">
                  <div className="attending-players-count">
                    {training.attendanceData.finalAttendance.absentCount}
                  </div>
                  Absagen
                </div>
                <div className="attendance-info">
                  <div className="attending-players-count">
                    {training.attendanceData.finalAttendance.pendingCount}
                  </div>
                  Ausstehend
                </div>
              </div>


              <div className="training-list__groups">
                {
                  training.trainingGroups.map((trainingGroup) => {
                    return <div className="training-list__group"
                                key={trainingGroup.slug}>{trainingGroup.name}</div>
                  })
                }
              </div>
            </div>

          </div>

          <div className="training-list__bottom-row">
            {type !== 'confirmation' &&
              <div className='training-list__details-toggle--wrapper'
                   onClick={() => setShowAttendanceDetails(current => !current)}>
                <div className='training-list__details-toggle'>
                  Details
                </div>

                {trainingDetails.trainingAnnouncement !== '' &&
                  <div className='training-list__details-toggle--announcement'/>
                }

                {trainingDetails.trainingNotes !== '' &&
                  <div className='training-list__details-toggle--notes'/>
                }
              </div>
            }
            {type === 'confirmation' &&
              <div/>
            }

            <div className="training-list__edit-training-toggle"
                 onClick={() => setIsEditTrainingPopupActive(true)}
            >
              Training bearbeiten
            </div>
          </div>


          {type !== 'confirmation' && showAttendanceDetails &&
            <>
              <div className="training-list__attendance-details-popup">
                <h3>Spieler:innen-Übersicht</h3>

                <div className="training-list__attendance-list-type-toggle">
                  <div onClick={() => handleAttendanceListTypeClick('attending')}
                       className={'training-list__attendance-list attending' + (activeAttendanceListType === 'attending' ? ' active' : '')}>
                    ZUSAGE
                  </div>
                  <div onClick={() => handleAttendanceListTypeClick('absent')}
                       className={'training-list__attendance-list absent' + (activeAttendanceListType === 'absent' ? ' active' : '')}>
                    ABSAGE
                  </div>
                  {type !== 'past' &&
                    <div onClick={() => handleAttendanceListTypeClick('pending')}
                         className={'training-list__attendance-list pending' + (activeAttendanceListType === 'pending' ? ' active' : '')}>
                      AUSSTEHEND
                    </div>
                  }
                </div>

                <div className="training-list__attending-players-list">
                  <div className="training-list__female-players">
                    {
                      trainingListPlayers.map((attendingPlayer) => {
                        if (attendingPlayer.gender !== 'f') {
                          return '';
                        }
                        let playerClassNames = 'training-list__attendance-details-popup--player ' + attendingPlayer.gender;
                        return (
                          <div className={playerClassNames} key={attendingPlayer.ID}>
                            <div className="player__name">
                              {attendingPlayer.nickname} {attendingPlayer.jersey_nr}
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="training-list__male-players">
                    {
                      trainingListPlayers.map((attendingPlayer) => {
                        if (attendingPlayer.gender !== 'm') {
                          return '';
                        }
                        let playerClassNames = 'training-list__attendance-details-popup--player ' + attendingPlayer.gender;
                        return (
                          <div className={playerClassNames} key={attendingPlayer.ID}>
                            <div className="player__name">
                              {attendingPlayer.nickname} {attendingPlayer.jersey_nr}
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>

                {trainingDetails.trainingAnnouncement !== '' &&
                  <div>
                    <br/><br/>
                    <h3>Anmerkung (öffentlich)</h3>
                    {trainingDetails.trainingAnnouncement}
                  </div>
                }

                {trainingDetails.trainingNotes !== '' &&
                  <div>
                    <br/><br/>
                    <h3>Notizen (intern)</h3>
                    {trainingDetails.trainingNotes}
                  </div>
                }

              </div>
            </>
          }

          {type === 'confirmation' &&
            <button className="aug-button coaches training-list__button-edit-training"
                    onClick={() => setIsCompleteTrainingPopupActive(true)}
            >
              Training abschließen
            </button>
          }

          {type !== 'confirmation' && trainingDetails.trainingStatus === 'closed' &&
            <button className="aug-button coaches secondary training-list__button-edit-training"
                    onClick={() => setIsCompleteTrainingPopupActive(true)}
            >
              Teilnahme bearbeiten
            </button>
          }
        </div>
      </div>


      {isEditTrainingPopupActive &&
        <div className="training-list__edit-training-popup--wrapper">
          <div className="training-list__edit-training-popup">
            <div className="close-popup" onClick={() => {
              setIsEditTrainingPopupActive(false);
              setTrainingDetails(originalTrainingDetails);
            }}>
              SCHLIESSEN
            </div>

            {savingTrainingDetails &&
              <div className="loader-wrapper">
                <Loader/>
              </div>
            }


            <div className="training-list__edit-training-popup--heading">
              Trainingstatus ändern:
            </div>
            <select value={trainingDetails.trainingStatus}
                    onChange={(event) => {
                      setTrainingDetails((current) => ({...current, trainingStatus: event.target.value}))
                    }}>
              <option value="open">Anmeldung offen</option>
              <option value="closed">Anmeldung geschlossen</option>
              <option value="completed" disabled={true}>Training abgeschlossen</option>
              <option value="canceled">Training abgesagt</option>
              <option value="pending" disabled={true}>In Bearbeitung</option>
            </select>
            <br/>

            <div className="training-list__edit-training-popup--heading">
              Anmerkung (öffentlich) ändern:
            </div>
            <textarea value={trainingDetails.trainingAnnouncement}
                      onChange={(event) => {
                        setTrainingDetails((current) => ({
                          ...current,
                          trainingAnnouncement: event.target.value
                        }))
                      }}
                      rows={2}/>
            <br/>

            <div className="training-list__edit-training-popup--heading">
              Notiz (intern) ändern:
            </div>
            <textarea value={trainingDetails.trainingNotes}
                      onChange={(event) => {
                        setTrainingDetails((current) => ({
                          ...current,
                          trainingNotes: event.target.value
                        }))
                      }}
                      rows={6}/>


            <button className="aug-button"
                    onClick={() => {
                      setNewTrainingDetails();
                    }}
            >
              SPEICHERN
            </button>
            <button className="aug-button secondary"
                    onClick={() => {
                      setIsEditTrainingPopupActive(false);
                      setTrainingDetails(originalTrainingDetails);
                    }}
            >
              Abbrechen
            </button>
          </div>
        </div>
      }


      {isCompleteTrainingPopupActive &&
        <div className="training-list__edit-training-popup--wrapper">
          <div className="training-list__edit-training-popup">
            <Scrollbar>
              <div className="training-list__edit-training-popup--content">
                <div className="close-popup"
                     onClick={() => setIsCompleteTrainingPopupActive(false)}>SCHLIESSEN
                </div>
                <br/>
                {type === 'confirmation' &&
                  <div>Trainingszusagen bestätigen:</div>
                }
                {type !== 'confirmation' &&
                  <div>Trainingszusagen bearbeiten:</div>
                }
                <br/>

                {savingTrainingDetails &&
                  <div className="loader-wrapper">
                    <Loader/>
                  </div>
                }

                <ConfirmationPlayersList allPlayers={allPlayers} training={training}
                                         type={type}
                                         handleConfirmationListSend={handleConfirmationListSend}/>

                <button className="aug-button secondary"
                        onClick={() => setIsCompleteTrainingPopupActive(false)}
                >
                  Abbrechen
                </button>
              </div>
            </Scrollbar>
          </div>
        </div>
      }
    </>
  )
}

export default SingleTraining;