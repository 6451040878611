import React, {useEffect, useState} from "react";
import wpDataService from "../../../services/wp-data-service";
import Loader from "../../Loader";

export default function MonthlyFameInfoBox() {
  const [famePlayerData, setfamePlayerData] = useState({});
  const [isLoadingfamePlayerData, setIsLoadingfamePlayerData] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getLastMonthsFamePlayerData().then(
      (result) => {
        console.log('Received last month\'s fame-players:');
        console.log(result);

        setfamePlayerData(result.data);
        setIsLoadingfamePlayerData(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingfamePlayerData(false);
      }
    );
  }, [])


  if (isLoadingfamePlayerData) {
    return (
      <div className={'fame-info-box dummy'}>
        <div className={'fame-info-box__heading-row'}>
          <div className={'fame-info-box__heading'}>Meadow of Fame</div>
          <div className={'fame-info-box__heading-month'}></div>
        </div>
        <div className={'fame-info-box__intro'}></div>
        <div className={'fame-info-box__players'}>
          <Loader/>
        </div>

        <div className={'bg-grass'}/>
      </div>
    );
  }

  if (!isLoadingfamePlayerData && !Object.keys(famePlayerData.famePlayers).length) {
    return (
      <div className={'fame-info-box dummy'}>
        <div className={'fame-info-box__heading-row'}>
          <div className={'fame-info-box__heading'}>Meadow of Fame</div>
          <div className={'fame-info-box__heading-month'}></div>
        </div>
        <div className={'fame-info-box__intro'}></div>
        <div className={'fame-info-box__players'} style={{color: 'white'}}>
          Keine :(
        </div>

        <div className={'bg-grass'}/>
      </div>
    );
  }

  let famePlayers = famePlayerData.famePlayers;
  let monthName = famePlayerData.month;

  return (
    <div className={'fame-info-box'}>
      <div className={'fame-info-box__heading-row'}>
        <div className={'fame-info-box__heading'}>Meadow of Fame</div>
        <div className={'fame-info-box__heading-month'}>{monthName}</div>
      </div>
      <div className={'fame-info-box__intro'}>
        100% Trainingsanwesenheit im {monthName}:
      </div>
      <div className={'fame-info-box__players'}>
        {
          famePlayers.map((famePlayer) => (
            <div className={'fame-player'} key={famePlayer.ID}>
              <div className={'fame-player__bug-icon'}></div>
              {famePlayer.nick} {famePlayer.jerseyNr}
            </div>
          ))
        }
      </div>

      <div className={'bg-grass'}/>
    </div>
  );
}