import axios from 'axios' ;

class wpDataService {
  constructor() {

    console.log('Starting WordPress data-service...');

    let site_url;
    switch (window.location.hostname) {
      case 'localhost':
        site_url = 'https://augaertner.test';
        break;
      case 'my.aug.onl':
        site_url = 'https://augaertner-ultimate.at';
        break;
      default:
        site_url = 'https://augaertner-ultimate.at';
    }
    this.api_url = site_url + '/wp-json/myaugaertner/v1';
    console.log('Location-URL is: ' + window.location.hostname);
    console.log('API-URL is: ' + this.api_url);

    //this.api_token = 'YXVnYWVydG5lci13cGFkbWluOkN3MWcgYWVBdiBKUDdkIHd5Z3ggQUVmVCBZQ2Jk'; //local-test
    this.api_token = 'YXVnYWVydG5lci13cGFkbWluOmY3SmkgM3g4TiA1aHR4IE1FZWggSGc4aiA4U1hW'; //production

    // avoid a warning
    axios.interceptors.request.use(function (config) {
      const {headers = {}} = config || {};
      if (headers['User-Agent']) {
        delete config.headers['User-Agent'];
      }
      // console.log(headers);

      return config;
    })
  }

  getMemberData(userID) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    // console.log(`${this.api_url}/member/` + userID, {headers: {header}});
    return axios.get(`${this.api_url}/member/getMemberData/` + userID, {headers: header});
  }

  getClubMemberPrivacyTexts() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/member/getClubPrivacyTexts`, {headers: header});
  }

  getMemberAppProfileOptions(userID) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/member/getAppProfileOptions/` + userID, {headers: header});
  }

  setMemberAppProfileOptions(id, data) {
    const postData = {
      memberID: id,
      options: data,
    }
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.post(`${this.api_url}/member/setAppProfileOptions`, postData, {headers: header});
  }

  getInfoNotifications() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    // console.log(`${this.api_url}/info/notification`, {headers: {header}});
    return axios.get(`${this.api_url}/info/notifications`, {headers: header});
  }

  // members
  getMembersList() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    // console.log(`${this.api_url}/members-list/`, {headers: {header}});
    return axios.get(`${this.api_url}/members-list`, {headers: header});
  }

  getAdminMemberFeeInfo() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    // console.log(`${this.api_url}/admin/member_fee_info`, {headers: {header}});
    return axios.get(`${this.api_url}/admin/member_fee_info`, {headers: header});
  }

  getAdminMemberData() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/admin/members-list`, {headers: header});
  }


  //club
  getClubInfoData() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/club`, {headers: header});
  }

  getClubDates() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/club/clubDates`, {headers: header});
  }

  //team
  getTeamInfoDataForTrainers() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/team`, {headers: header});
  }

  // member fee
  setMemberFeeUserFeedback(userID, newUserFeedback) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/memberFee/setMemberFeeUserFeedback/`, {
      userID: userID,
      userFeedback: newUserFeedback
    }, {headers: header});
    return axios.post(`${this.api_url}/memberFee/setMemberFeeUserFeedback/`, {
      userID: userID,
      userFeedback: newUserFeedback
    }, {headers: header});
  }


  // trainings
  getTrainingsForMember(userID) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/getTrainingForPlayer/` + userID, {headers: {header}});
    return axios.get(`${this.api_url}/training/getTrainingForPlayer/` + userID, {headers: header});
  }

  getUpcomingTrainingsShortlistForMember(userID) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/getUpcomingTrainingsShortlistForPlayer/` + userID, {headers: {header}});
    return axios.get(`${this.api_url}/training/getUpcomingTrainingsShortlistForPlayer/` + userID, {headers: header});
  }


  setTrainingStatusForMember(userID, trainingID, trainingStatus) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/setTrainingStatus`, {
      user: userID,
      training: trainingID,
      status: trainingStatus
    }, {headers: header});
    return axios.post(`${this.api_url}/training/setTrainingStatus/`, {
      user: userID,
      training: trainingID,
      status: trainingStatus
    }, {headers: header});
  }

  // past trainings
  getPastTrainingsForMember(userID, activeSeasonID = null) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    const request = {
      email: userID,
      seasonID: activeSeasonID,
    }
    console.log(`${this.api_url}/training/getPastTrainingForPlayer/`, request, {headers: {header}});
    return axios.post(`${this.api_url}/training/getPastTrainingForPlayer/`, request, {headers: header});
  }

  // training stats
  getPlayersTrainingStats(activeSeasonID) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/getPlayersTrainingStats/` + activeSeasonID, {headers: {header}});
    return axios.get(`${this.api_url}/training/getPlayersTrainingStats/` + activeSeasonID, {headers: header});
  }

  getAllSeasons() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/getAllSeasons/`, {headers: {header}});
    return axios.get(`${this.api_url}/training/getAllSeasons/`, {headers: header});
  }

  // last month's fame
  getLastMonthsFamePlayerData() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/getLastMonthsFamePlayers/`, {headers: {header}});
    return axios.get(`${this.api_url}/training/getLastMonthsFamePlayers/`, {headers: header});
  }

  // TRAINERS training overview
  getTrainerUpComingTrainingsData() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/training/trainer/getUpcomingTrainings`, {headers: header});
  }
  getTrainerPastTrainingsData() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/training/trainer/getPastTrainings`, {headers: header});
  }

  getClosedTrainingsPastDeadline() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/training/trainer/getClosedTrainingsPastDeadline`, {headers: header});
  }

  getCoachesNextUpcomingTraining() {
    const header = {'Authorization': `Basic ${this.api_token}`};
    return axios.get(`${this.api_url}/training/trainer/getNextUpcomingTraining`, {headers: header});
  }

  setTrainingDetails(trainingID, trainingState, trainingAnnouncement, trainingNotes) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/trainer/setTrainingDetails/`, {
      trainingID: trainingID,
      trainingState: trainingState,
      trainingAnnouncement: trainingAnnouncement,
      trainingNotes: trainingNotes
    }, {headers: header});
    return axios.post(`${this.api_url}/training/trainer/setTrainingDetails/`, {
      trainingID: trainingID,
      trainingState: trainingState,
      trainingAnnouncement: trainingAnnouncement,
      trainingNotes: trainingNotes
    }, {headers: header});
  }

  setTrainingAttendanceConfirmation(trainingID, confirmationList, excludeFromPenaltyList, completeTraining = false) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/training/trainer/setTrainingAttendanceConfirmationList/`, {
      trainingID: trainingID,
      confirmationList: confirmationList,
      excludeFromPenaltyList: excludeFromPenaltyList,
      completeTraining: completeTraining
    }, {headers: header});
    return axios.post(`${this.api_url}/training/trainer/setTrainingAttendanceConfirmationList/`, {
      trainingID: trainingID,
      confirmationList: confirmationList,
      excludeFromPenaltyList: excludeFromPenaltyList,
      completeTraining: completeTraining
    }, {headers: header});
  }


  // finances
  getFinancesForMember(userID) {
    const header = {'Authorization': `Basic ${this.api_token}`};
    console.log(`${this.api_url}/finances/` + userID, {headers: {header}});
    return axios.get(`${this.api_url}/finances/` + userID, {headers: header});
  }
}

export default wpDataService;


