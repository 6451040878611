import UpcomingTrainingsShortlist from "./UpcomingTrainingsShortlist/";
import {Link} from "react-router-dom";
import PlayerTrainingStatsInfo from "./training-components/PlayerTrainingStatsInfo";

function Training({userData, userRoles}) {
  let userID = userData.userID;
  let userMail = userData.email;
  return (
    <main>
      <h2>Training</h2>

      <h3>Bevorstehende Trainings</h3>
      <UpcomingTrainingsShortlist userEmail={userMail} userID={userID} userRoles={userRoles}/>
      <br/>


      <h3>Trainingsrückblick</h3>
      <PlayerTrainingStatsInfo userData={userData}/>
      <br/>
      <Link to="/training/stats" className="aug-button chevron-right wide no-margin"
            style={{width: '100%', margin: 0,}}>
        Season-Stats
      </Link>
      <br/>
      <Link to="/training/past-trainings" className="aug-button chevron-right secondary wide no-margin"
            style={{width: '100%', margin: 0,}}>
        Vergangene Trainings
      </Link>

      <br/>
    </main>
  )
}

export default Training;