import React, {useState} from "react";

export default function MemberDataProfile({member}) {
  const [profileExpanded, setProfileExpanded] = useState(false);
  let profilePicStyle = {};
  if (member.profilePic !== '') {
    profilePicStyle = {backgroundImage: 'url("' + member.profilePic + '")'}
  }

  return (
    <>
      <div className="member-list__row player-row"
           onClick={() => setProfileExpanded((current) => !current)}>
        <div className="member-list__profile-pic" style={profilePicStyle}/>
        <div className="member-list__member-info">
          <div className={'member-list__member-status-icon ' + member.memberType}/>
          <div className="name">
            {member.nick} #{member.jerseyNr}
          </div>
          <div className={'member-role-tags'}>
            <div className={"member-role-tag " + member.memberType}>
              {getMemberTypeTitle(member.memberType)}
            </div>
            {member.roles.map(memberRole => {
              if (memberRole !== 'member' && memberRole !== 'administrator') {
                let roleName = ''
                switch (memberRole) {
                  case 'board-member':
                    roleName = 'Vorstand';
                    break;
                  case 'cashier':
                    roleName = 'Finanzen';
                    break;
                  case 'trainer':
                    roleName = 'Trainer'
                    break;
                  default:
                    roleName = '';
                }
                return (<div key={member.nick + member.jerseyNr + memberRole}
                             className={'member-role-tag ' + memberRole}>{roleName}</div>);
              }
              return '';
            })}
          </div>
          <br/>
          <div className={'member-list__status-icons'}>
            <div className={'member-list__team-status-icon--' + member.teamStatus.value}/>
            <div className={'member-list__team-fee-status ' + member.feeStatus}>
              MGB:
              <div className={'member-list__team-fee-status-icon ' + member.feeStatus}/>
            </div>
          </div>
        </div>

        <div className={'member-list__number'}>
          <div className={'member-list__number-inner'}>{member.jerseyNr}</div>
        </div>
      </div>

      {
        profileExpanded && (
          <div className={'member-list__row-expanded'}>

            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Mitgliedsbeitrag
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__team-fee-status ' + member.feeStatus}>
                  <div className={'member-list__team-fee-status-icon ' + member.feeStatus}/>
                  {member.feeStatus === 'outstanding' &&
                    'ausstehend'
                  }
                  {member.feeStatus === 'paid' &&
                    'bezahlt'
                  }
                  {member.feeStatus === 'exempt' &&
                    'ausgenommen'
                  }
                </div>
              </div>
            </div>

            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Voller Name
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                {member.fullName}
              </div>
            </div>

            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Geburtstag
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                {member.birthday}
              </div>
            </div>

            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Kontakt
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__mail-icon'}/>
                <a href={'mailto:' + member.mail}>{member.mail}</a>
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__phone-icon'}/>
                <a href={'tel:' + member.phone}>{member.phone}</a>
              </div>
            </div>

            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Mitgliedschaft:
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__member-status-icon ' + member.memberType}/>
                <div className={'member-list__member-status-name ' + member.memberType}>
                  {getMemberTypeTitle(member.memberType)}
                </div>
              </div>
            </div>

            {member.entryYear && (
              <div className={'member-list__row-expanded-field'}>
                <div className={'member-list__row-expanded-field--label'}>
                  Im Verein seit:
                </div>
                <div className={'member-list__row-expanded-field--value'}>
                  <div className={'member-list__entry-year'}>
                    <div>{member.entryYear}</div>
                  </div>

                  {member.clubYears > 0 &&
                    <div className={'member-list__club-years--wrapper'}>
                      <div className={'member-list__club-years'}>
                        <div className={'member-list__club-years--number'}>
                          {member.clubYears}
                        </div>
                      </div>
                      Jahre
                    </div>
                  }
                </div>
              </div>
            )}

            <div className={'member-list__row-expanded-field'}>
              <div className={'member-list__row-expanded-field--label'}>
                Team-Status:
              </div>
              <div className={'member-list__row-expanded-field--value'}>
                <div className={'member-list__team-status ' + member.teamStatus.value}>
                  <div className={'member-list__team-status-icon--' + member.teamStatus.value}/>
                  <div>{member.teamStatus.label}</div>
                </div>
              </div>
            </div>


            {(member.teamStatus.value === 'active' && member.trainingGroups) &&
              <div className={'member-list__row-expanded-field'}>
                <div className={'member-list__row-expanded-field--label'}>
                  Trainingsgruppen:
                </div>
                <div className={'member-list__row-expanded-field--value'}>
                  <div className={'member-list__training-groups'}>
                    {member.trainingGroups.map((trainingGroup) => (
                      <div key={trainingGroup.slug} style={{backgroundColor: trainingGroup.color}}
                           className={'member-list__training-group'}>{trainingGroup.name}</div>
                    ))}
                  </div>
                </div>
              </div>
            }

            {member.lastLogin &&
              <div className={'member-list__row-expanded-field'}>
                <div className={'member-list__row-expanded-field--label'}>
                  Letzer App-Login
                </div>
                <div className={'member-list__row-expanded-field--value'}>
                  {member.lastLogin.formatted}
                </div>
              </div>
            }

            {/*Trainingsgruppen?*/}
          </div>
        )
      }
    </>
  )
    ;


  function getMemberTypeTitle(memberTypeSlug) {
    switch (memberTypeSlug) {
      case 'honourable':
        return 'Ehrenmitglied';
      case 'supporting':
        return 'Förderndes Mitglied';
      case 'idle':
        return 'Außerordentliches Mitglied';
      default:
        return 'Ordentliches Mitglied';
    }
  }
}