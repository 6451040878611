import {NavLink, Link} from "react-router-dom";
import VersionChangeLog from "../../VersionChangeLog";

function SecondaryMenu({Logout, userRoles, toggleMenu}) {
  if (Object.keys(userRoles).length === 0) {
    return 'Loading';
  }

  return (
    <ul className="header-bar-menu">
      <li className="header-bar-menu__item">
        <NavLink className="item-link main-link" onClick={toggleMenu} to="/">HOME</NavLink>
      </li>


      <li className="header-bar-menu__item">
        <NavLink className="item-link main-link" onClick={toggleMenu}
                 to="/training">TRAINING</NavLink>
        <ul className="header-bar-submenu">
          <li>
            <NavLink className="item-link" onClick={toggleMenu}
                     to="/training/registration">Trainingszusage</NavLink>
          </li>
          <li>
            <NavLink className="item-link" onClick={toggleMenu} to="/training/stats">
              Season-Stats
            </NavLink>
          </li>
          <li>
            <NavLink className="item-link" onClick={toggleMenu} to="/training/past-trainings">Vergangene
              Trainings</NavLink>
          </li>
        </ul>
      </li>


      {userRoles.includes('trainer') &&

        <li className="header-bar-menu__item">
          <NavLink className="item-link main-link item-link__coaches" onClick={toggleMenu}
                   to="/coaches/">COACHES
            CORNER</NavLink>
          <ul className="header-bar-submenu">
            <li>
              <NavLink className="item-link" onClick={toggleMenu}
                       to="/coaches/upcoming-trainings">Trainingsvorschau</NavLink>
            </li>
            <li>
              <NavLink className="item-link" onClick={toggleMenu}
                       to="/coaches/past-trainings">Vergangene Trainings</NavLink>
            </li>
          </ul>
        </li>
      }

      {/*<li className="header-bar-menu__item">*/}
      {/*    <Link className="item-link main-link disabled" onClick={toggleMenu}*/}
      {/*          to="#">FINANZIELLES</Link>*/}
      {/*</li>*/}


      <li className="header-bar-menu__item">
        <NavLink className="item-link main-link" onClick={toggleMenu} to="/club">VEREIN</NavLink>
        <ul className="header-bar-submenu">
          <li>
            <NavLink className="item-link" onClick={toggleMenu} to="/club/quicklinks">Docs & Links</NavLink>
          </li>
          <li>
            <NavLink className="item-link" onClick={toggleMenu}
                     to="/club/contacts">Kontakte</NavLink>
          </li>
          <li>
            <NavLink className="item-link" onClick={toggleMenu}
                     to="/club/members">Mitglieder</NavLink>
          </li>
          {(userRoles.includes('trainer') || userRoles.includes('board-member')) &&
            <li className="header-bar-menu__item">
              <NavLink className="item-link item-link__board-members" onClick={toggleMenu} to="/club/member-data">Mitgliederdaten</NavLink>
            </li>
          }
        </ul>
      </li>

      <li className="header-bar-menu__item">
        <NavLink className="item-link main-link" onClick={toggleMenu} to="/data">PERSÖNLICHE
          DATEN</NavLink>
      </li>

      <li className="header-bar-menu__item">
        <Logout/>
      </li>

      <li style={{display: 'flex'}}>
        <div style={{padding: '15px 20px 0 10px', fontSize: 10, color: 'grey',}}>
          <VersionChangeLog />
        </div>
        <Link className="refresh-app-link" onClick={() => {
          toggleMenu();
          window.location.reload()
        }} to="#">Refresh app</Link>
      </li>
    </ul>
  );
}

export default SecondaryMenu;
