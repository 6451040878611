import React from "react";

export default function Quicklinks({clubData}) {
  if (Object.keys(clubData).length === 0) {
    return 'Loading';
  }

  return (
    <main className="quicklinks">
      <h2>Dokumente & Links</h2>
      <div dangerouslySetInnerHTML={{__html: clubData.quicklinks}}/>
    </main>
  );
}