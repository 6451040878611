import React, {useEffect, useState} from "react";
import wpDataService from "../../../services/wp-data-service";
import Loader from "../../Loader";
import SingleTraining from "./SingleTraining";
import {Link} from "react-router-dom";

function PastTrainings({userRoles}) {
  // trainer-data
  const [trainingsData, setTrainingsData] = useState(null);

  useEffect(() => {
    const dataService = new wpDataService();
    if (Object.keys(userRoles).length > 0 && userRoles.includes('trainer')) {
      dataService.getTrainerPastTrainingsData().then(
        (result) => {
          console.log('COACHES-CORNER: Received TRAINER-data');
          console.log(result.data);
          if (result.data !== 'error') {
            setTrainingsData(result.data);
          }
        },
        (error) => {
          if (error.response) {
            console.warn(error.response.data);
          } else {
            console.error(error);
          }
        }
      );
    }
  }, [userRoles])

  if (trainingsData === null) {
    return (
      <main className="coaches__past-trainings">
        <Link className="back-to-link" to={'/coaches/'}>
          {'<'} Zurück zum Coaches Corner
        </Link>
        <h2>Vergangene Trainings</h2>
        <Loader/>
      </main>
    )
  }

  if (trainingsData.length === 0) {
    return (
      <main className="coaches__past-trainings">
        <Link className="back-to-link" to={'/coaches/'}>
          {'<'} Zurück zum Coaches Corner
        </Link>
        <h2>Vergangene Trainings</h2>
        Keine vergangenen Trainings vorhanden..
      </main>
    )
  }

  let pastTrainings = trainingsData.reduce(
    (prevValue, currentValue, index) => {
      return {...prevValue, [currentValue.month]: {...prevValue[currentValue.month], [index]: currentValue}};
    },
    0
  );
  return (
    <main className="coaches__past-trainings">
      <Link className="back-to-link" to={'/coaches/'}>
        {'<'} Zurück zum Coaches Corner
      </Link>
      <h2>Vergangene Trainings</h2>
      <div className="training-list training-shortlist coaches-upcoming-trainings coaches-past-trainings">
        {
          Object.keys(pastTrainings).map((trainingMonth) => {
            let pastTrainingsMonth = pastTrainings[trainingMonth];
            return (
              <div className="training-list__month-box" key={trainingMonth}>
                <div className="training-list__month-heading">
                  {trainingMonth}
                </div>
                {
                  Object.keys(pastTrainingsMonth).map((training) => (
                    <SingleTraining key={training} training={pastTrainingsMonth[training]}
                                    type={'past'} previewMode={false}/>
                  ))
                }
              </div>
            );
          })
        }
      </div>
    </main>
  )
}

export default PastTrainings;