import {Link} from "react-router-dom";
import {useState} from "react";

export default function HomeAppInfoPopUp({playerName}) {
  const [popupIsVisible, setPopupIsVisible] = useState(true);

  if (!popupIsVisible) {
    return '';
  }

  return (
    <div className={'myaug-popup__wrapper'}>
      <div className={'myaug-popup home-app-info-popup'}>
        <div className={'home-app-info-popup__header-bar'}>
          App-Update v1.0.5 - 07/2023
        </div>
        <div className={'home-app-info-popup__content'}>
          <div style={{display: 'flex'}}>
            <h3><span style={{textTransform: 'none'}}>Neu: </span>Profil-Einstellungen</h3>
          </div>
          <p>
            Hallo, {playerName}!
          </p>
          <p>
            Bitte lege unter <Link to={'/data'}>Persönliche Daten</Link> fest, welche deiner Kontaktinfos du mit den anderen Vereinsmitgliedern über die
            "Mitglieder"-Liste in der App teilen möchtest.
          </p>
          <Link to={'/data'} className={'aug-button'}>
            Zu den Einstellungen
          </Link>
          <div style={{color: '#c5a135', textAlign: 'right'}} onClick={() => setPopupIsVisible((current) => !current)}>
            Später einstellen...
          </div>
        </div>
      </div>
    </div>
  );
}