import React, {useEffect, useState} from "react";
import wpDataService from "../../services/wp-data-service";
import Loader from "../Loader";

export default function ClubStatsBox() {
  const [memberList, setMemberList] = useState({});
  const [isLoadingMemberList, setIsLoadingMemberList] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getMembersList().then(
      (result) => {
        console.log('CLUB STATS BOX: Received data:');
        console.log(result);

        setMemberList(result.data);
        setIsLoadingMemberList(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingMemberList(false);
      }
    );
  }, [])

  if (isLoadingMemberList) {
    return (
      <div className={'club-stats-box dummy'}>
        <div className={'club-stats-box__heading'}>Club-Stats</div>
        <div className={'club-stats-box__content'}>
          <div className={'loader-wrapper'}>
            <Loader/>
          </div>

          <div className={'club-stats-box__members-count'}>
          </div>

          <div className={'club-stats-box__members-subcounts'}>
            <div className={'club-stats-box__members-subcount'}/>
            <div className={'club-stats-box__members-subcount'}/>
            <div className={'club-stats-box__members-subcount'}/>
          </div>
        </div>
      </div>
    )
  }

  if (!isLoadingMemberList && !Object.keys(memberList).length) {
    return '';
  }


  let fullMembers = memberList.filter((member) => {
    return member.memberType === 'full';
  });

  let honourableMembers = memberList.filter((member) => {
    return member.memberType === 'honourable';
  });

  let supportingMembers = memberList.filter((member) => {
    return member.memberType === 'supporting';
  });

  let idleMembers = memberList.filter((member) => {
    return member.memberType === 'idle';
  });

  return (
    <div className={'club-stats-box'}>
      <div className={'club-stats-box__heading'}>Club-Stats</div>

      <div className={'club-stats-box__content'}>

        <div className={'club-stats-box__members-count'}>
          <div className={'club-stats-box__members-count--count'}>
            {memberList.length}
          </div>

          <div className={'club-stats-box__members-count--description'}>
            Mitglieder
          </div>
        </div>

        <div className={'club-stats-box__members-subcounts'}>
          {fullMembers.length !== 0 &&
            <div className={'club-stats-box__members-subcount full'}>
              <div className={'club-stats-box__members-subcount--count'}>{fullMembers.length}</div>
              <div className={'club-stats-box__members-subcount--description'}>Ordentliche Mitglieder
              </div>
            </div>
          }
          {honourableMembers.length !== 0 &&
            <div className={'club-stats-box__members-subcount honourable'}>
              <div className={'club-stats-box__members-subcount--count'}>{honourableMembers.length}</div>
              <div className={'club-stats-box__members-subcount--description'}>Ehrenmitglieder</div>
            </div>
          }
          {supportingMembers.length !== 0 &&
            <div className={'club-stats-box__members-subcount supporting'}>
              <div className={'club-stats-box__members-subcount--count'}>{supportingMembers.length}</div>
              <div className={'club-stats-box__members-subcount--description'}>Fördernde Mitglieder</div>
            </div>
          }
          {idleMembers.length !== 0 &&
            <div className={'club-stats-box__members-subcount idle'}>
              <div className={'club-stats-box__members-subcount--count'}>{idleMembers.length}</div>
              <div className={'club-stats-box__members-subcount--description'}>Außerordentliche</div>
            </div>
          }
        </div>

      </div>
    </div>
  )
}