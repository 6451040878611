import React, {useEffect, useRef, useState} from "react";
import AttendanceStatusButton from "./AttendanceStatusButton";
import {toast} from "react-toastify";
import wpDataService from "../../services/wp-data-service";
import Loader from "../Loader";
import {Link} from "react-router-dom";

function TrainingRegistration({userID, userEmail}) {
  let prevMonthName = '';
  const attendancePopupTriggers = useRef({});
  const attendancePopups = useRef({});
  const [selectedAttendance, setSelectedAttendance] = useState({});
  const [currentlySavingTraining, setCurrentlySavingTraining] = useState([]);

  const [trainingData, setTrainingData] = useState({});
  const [isLoadingTrainingData, setIsLoadingTrainingData] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getTrainingsForMember(userEmail).then(
      (result) => {
        console.log('Received WordPress-data TRAINING FOR PLAYER:');
        console.log(result.data);
        if (result.data !== 'error') {
          setTrainingData(result.data);
        }
        setIsLoadingTrainingData(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingTrainingData(false);
      }
    );
  }, [userEmail]);


  const setNewTrainingAttendanceStatus = (trainingID, trainingStatus) => {
    const dataService = new wpDataService();
    dataService.setTrainingStatusForMember(userID, trainingID, trainingStatus).then(
      (result) => {
        console.log('Training-attendance saved!');
        console.log(result.data);
        setTrainingData(result.data);

        // Remove loading from training-unit
        setCurrentlySavingTraining(currentlySaving => {
          console.log('hier: ' + currentlySaving);
          console.log(currentlySaving.splice(currentlySaving.indexOf(trainingID), 1));
          return currentlySaving.splice(currentlySaving.indexOf(trainingID), 1);
        });
        toast.success("Trainingsteilnahme wurde gespeichert!");
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
          toast.error(error.response.data.error);

          // Remove loading from training-unit
          setCurrentlySavingTraining(currentlySaving => {
            console.log('hier: ' + currentlySaving);
            console.log('ho' + currentlySaving.splice(currentlySaving.indexOf(trainingID), 1));
            return currentlySaving.splice(currentlySaving.indexOf(trainingID), 1);
          });
        } else {
          console.error(error);
        }
      }
    );
  }

  if (isLoadingTrainingData) {
    return (
      <main className="training">
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <h2>Trainingsteilnahme</h2>
        <Loader/>
      </main>
    )
  }

  if (Object.keys(trainingData).length === 0) {
    return (
      <main className="training">
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <h2>Trainingsteilnahme</h2>
        <br/>
        Keine Trainings gefunden.
      </main>
    )
  }

  const handleClick = (event, trainingID, trainingStatus) => {
    if (trainingStatus === 'closed') {
      toast.error('Für diese Training sind keine Änderungen mehr möglich. Bitte poste Änderungen auf Discord im Channel #trainings-talk');
      return;
    }
    attendancePopups.current[trainingID].classList.toggle('active');
  }

  const saveTrainingAttendance = (trainingID) => {
    console.log(selectedAttendance[trainingID])
    setCurrentlySavingTraining(currentlySaving => [...currentlySaving, trainingID]);
    setNewTrainingAttendanceStatus(trainingID, selectedAttendance[trainingID]);
  }

  return (
    <main className="training">
      <Link className="back-to-link" to={'/training/'}>
        {'<'} Zurück zur Trainingsübersicht
      </Link>
      <h2>Trainingsteilnahme</h2>
      <br/>
      <div className="training-list training-registration-list">
        {
          trainingData.map((training, index) => {
            let trainingUnitClassNames = 'training-list__training-unit' + (currentlySavingTraining.indexOf(training.ID) > -1 ? ' saving' : '');
            trainingUnitClassNames += (training.trainingStatus.value === 'closed' ? ' closed' : '');

            let trainingUnitStyle = {};
            let trainingUnitTitleStyle = {};
            if (training.trainingGroups.length > 0) {
              let color = training.trainingGroups[0].groupColor;
              trainingUnitStyle.borderColor = color;
              trainingUnitTitleStyle.color = color;
            }

            return (
              <React.Fragment key={training.ID}>
                {(prevMonthName !== training.month) && (prevMonthName = training.month) &&
                  <div className="training-list__month-heading" key={training.ID + training.month}>
                    {training.month}
                  </div>
                }
                <div className={trainingUnitClassNames}
                     style={trainingUnitStyle}
                     onClick={(event) => handleClick(event, training.ID, training.trainingStatus.value)}
                     ref={(element) => {
                       attendancePopupTriggers.current[training.ID] = element
                     }}
                >

                  <div className="training-list__content-column">
                    <div className="training-list__title-row">
                      <div className="training-list__title"
                           style={trainingUnitTitleStyle}>{training.data.post_title}</div>
                    </div>
                    <div
                      className="training-list__date">{training.weekday}, {training.formattedDate.substr(0, 6)}</div>
                    <div className="training-list__time">
                      <div>{training.timeFrom} - {training.timeUntil}</div>
                    </div>
                    {training.location.length !== 0 &&
                      <div className="training-list__location">
                        <div>{training.location[0].name}</div>
                      </div>
                    }
                    <div className="training-list__groups">
                      {
                        training.trainingGroups.map((trainingGroup) => {
                          let trainingUnitTagStyle = {
                            color: trainingGroup.groupColor,
                            borderColor: trainingGroup.groupColor,
                          }
                          return (
                            <div className="training-list__group"
                                 key={trainingGroup.slug}
                                 style={trainingUnitTagStyle}>
                              {trainingGroup.name}
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>


                  <AttendanceStatusButton training={training} userID={userID}/>

                  <div className="training-list__attendance-popup" ref={(element) => {
                    attendancePopups.current[training.ID] = element
                  }}>
                    <div className="training-list__attendance-popup--title">
                      Teilnahme:
                    </div>
                    <input name={'training-participation-select-' + training.ID}
                           type="radio"
                           value="yes"
                           id={'training-participating' + training.ID}
                           onClick={() => {
                             let newSelectedAttendance = {...selectedAttendance};
                             newSelectedAttendance[training.ID] = 'attending';
                             setSelectedAttendance(newSelectedAttendance);
                           }}
                           defaultChecked={Object.keys(training.attendance).some(key => key === (userID + '')) && training.attendance[userID] === 'attending'}
                    />
                    <label className="training-list__attendance-popup--item yes"
                           htmlFor={'training-participating' + training.ID}>
                      <div className="training-list__attendance-popup--item-icon yes"/>
                      JA
                    </label>
                    <input name={'training-participation-select-' + training.ID}
                           type="radio"
                           value="no"
                           id={'training-not-participating' + training.ID}
                           onClick={() => {
                             let newSelectedAttendance = {...selectedAttendance};
                             newSelectedAttendance[training.ID] = 'absent';
                             setSelectedAttendance(newSelectedAttendance);
                           }}
                           defaultChecked={Object.keys(training.attendance).some(key => key === (userID + '')) && training.attendance[userID] === 'absent'}
                    />
                    <label className="training-list__attendance-popup--item no"
                           htmlFor={'training-not-participating' + training.ID}>
                      <div className="training-list__attendance-popup--item-icon no"/>
                      NEIN
                    </label>
                    <div
                      className="training-list__save-selection"
                      onClick={() => {
                        saveTrainingAttendance(training.ID)
                      }}
                    >
                      Speichern
                    </div>
                  </div>

                </div>
              </React.Fragment>
            )
          })
        }
      </div>
    </main>
  );
}

export default TrainingRegistration;