import React, {useEffect} from "react";
import PullToRefresh from "pulltorefreshjs";
import {ToastContainer} from "react-toastify";
import AppLogin from "./AppLogin";
import VersionChangeLog from "../VersionChangeLog";

export default function LoginScreen({setUserData}) {
  useEffect(initiatePullToRefresh, []);

  function initiatePullToRefresh() {
    PullToRefresh.init({
      mainElement: '.login-screen',
      triggerElement: '.login-screen',
      distThreshold: 100,
      distMax: 120,
      distReload: 80,
      distIgnore: 5,
      distResisted: 60,
      onRefresh: function () {
        window.location.reload();
      },
      shouldPullToRefresh: function () {
        return !this.mainElement.scrollTop;
      },
      instructionsPullToRefresh: 'Ziehen für App-Refresh',
      instructionsReleaseToRefresh: 'Loslassen für App-Refresh'
    });
  }

  return (
    <div className="app-content login-screen">
      <div className="background-pattern"/>
      <div className="login-logo"/>
      <div className={"login-box"}>
        <AppLogin setUserData={setUserData}/>
        <div className="login-info">
          My.Gärtner-Login ist nur mit deinem @augaertner-ultimate.at User-Account möglich.<br/>
          Du musst außerdem Cookies auf dieser Seite erlauben. Aus diesem Grund ist der Login auch z.B. im
          Inkognito-Modus nicht möglich.
        </div>
      </div>

      <div style={{fontSize: '10px', color: 'grey', textAlign: 'center', margin: '10px 0 5px'}}>
        My.Gärtner <VersionChangeLog /> by Toni48
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={4500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme={'dark'}
      />
    </div>
  );
}