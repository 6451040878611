import {NavLink} from "react-router-dom";

function Navigation() {
  return (
    <ul className="navigation">

      <li className="navigation__link-item">
        <NavLink to="/" className={"navigation__link navigation__link--home"}/>
      </li>

      <li className="navigation__link-item">
        <NavLink to="/training" className={"navigation__link navigation__link--calendar"}/>
      </li>

      {/*<li className="navigation__link-item">*/}
      {/*    <NavLink to="#" className={"navigation__link navigation__link--financials"}/>*/}
      {/*</li>*/}

      <li className="navigation__link-item">
        <NavLink to="/club" className={"navigation__link navigation__link--club"}/>
      </li>

    </ul>
  );
}

export default Navigation;

