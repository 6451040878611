import packageJson from '../package.json';
import {useState} from "react";

export default function VersionChangeLog() {
  const [changeLogPopupVisible, setChangeLogPopupVisible] = useState(false);

  return (
    <>
      <span
        onClick={() => setChangeLogPopupVisible(current => !current)}>v{packageJson.version} - {packageJson.versionDate}</span>

      {changeLogPopupVisible &&
        <div style={{
          position: 'fixed',
          inset: '70px 10px 80px',
          padding: 20,
          background: 'rgba(0,0,0,0.9)',
          overflow: 'scroll',
          fontSize: '14px',
          color: 'lightgrey',
          maxWidth: '800px',
          margin: '0 auto',
          zIndex: 9999,
          boxShadow: '0 0 10px 6px rgba(0,0,0,0.4)',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            top: '-20px',
            background: 'black',
            padding: '10px 20px',
            margin: '-20px -20px 0',
          }}>
            <div style={{textDecoration: 'underline', fontWeight: '700', letterSpacing: 1}}>My.Gärtner Changelog</div>
            <div style={{color: 'rgb(197, 161, 53)', fontWeight: '600'}}
                 onClick={() => setChangeLogPopupVisible(current => !current)}>CLOSE
            </div>
          </div>
          <span style={{fontSize: '13px', color: 'grey'}}>
            Current app-version:
            <span style={{fontWeight: 800}}>
              &nbsp;v{packageJson.version}
            </span>
            <br/>
            Last update:
            <span style={{fontWeight: 800}}>
              &nbsp;{packageJson.versionDate}
            </span>
          </span>
          <br/><br/><br/>


          {/** CHANGELOG GOES HERE: **/}

          <span style={{fontWeight: 700}}>Roadmap 2023-2024</span>
          <hr/>
          <ul style={{paddingInlineStart: '20px'}}>
            <li> Planned features
              <ul style={{paddingInlineStart: '10px', listStyleType: 'disc'}}>
                <li>Training penalty-points</li>
                <li>Member-dues</li>
                <li>Training-Screen improvements & training-groups overview</li>
                <li>Extended "Meadow of Fame" (yearly overview)</li>
                <li>Google-Calendar integration</li>
                <li>Member-dues & cashiers-overview</li>
                <li><i>?? Tournaments</i></li>
              </ul>
            </li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.1 - Season stats</span>
          <hr/>
          <i>2023/11/05</i>
          <ul style={{paddingInlineStart: '10px'}}>
            <li>Added training-seasons (replaces "Käfer-Stats")</li>
            <li>Added season-stats training-overview</li>
            <li>Fixed training-stats filtered by training-group</li>
            <li>Refined training-stats & -lists</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.5.3 - Privacy texts, bugfixes</span>
          <hr/>
          <i>2023/10/24</i>
          <ul style={{paddingInlineStart: '10px'}}>
            <li>Added club's privacy texts to "Personal data"</li>
            <li>Fixed coaches-corner practise-completion popup (enable scrolling)</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.5.2 - Upcoming trainings info</span>
          <hr/>
          <i>2023/10/16</i>
          <ul style={{paddingInlineStart: '10px'}}>
            <li>Added attending-players info-popup to upcoming trainings list</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.5.1 - Personal data &#x2022; member-list improvements</span>
          <hr/>
          <i>2023/07/05</i>
          <ul style={{paddingInlineStart: '10px'}}>
            <li>Added birthday & gender to personal data</li>
            <li>Added "show full name" to app-profile options</li>
            <li>Added member-list search (name/nummer) and filters</li>
            <li>Added member-data search and filters for board-members & trainers</li>
            <li>Added club-subpages for contatcs & links</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.5 - Personal data &#x2022; member-list</span>
          <hr/>
          <i>2023/07/03</i>
          <ul style={{paddingInlineStart: '10px'}}>
            <li>Improved personal data</li>
            <li>Added app-profile options</li>
            <li>Improved member list</li>
            <li>Added member-data page for board-members & trainers</li>
            <li>Added in-app info-popup for update messages;</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.4 - Google-Login &#x2022; Restructuring</span>
          <hr/>
          <i>2023/03/26</i>
          <ul style={{paddingInlineStart: '10px'}}>
            <li>Restructuring of whole app, improvement of data-flow</li>
            <li>GoogleAuth rework:
              <br/>- Replaced deprecated google-react-login with new google-oauth package (incl. one-tap
              login)
            </li>
            <li>Added version-info & changelog (accessible via menu)</li>
            <li>Added Member-images (google profile-pics) to member list</li>
            <li>Added google profile-pic info to profile-page + link to google-profile</li>
            <li>Improved Coaches-Corner loading-times</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.3 - App-versioning &#x2022; Pull-down-refresh</span>
          <hr/>
          <i>2023/02/13</i>
          <ul style={{paddingInlineStart: '20px'}}>
            <li>Added pullToRefresh-functionality</li>
            <li>Added basic Meadow of Fame (only for last month)</li>
            <li>Added club-stats</li>
            <li>Added club-dates</li>
            <li>Improved member-list</li>
            <li>Bugfixes</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.2 - Training-lists & -stats &#x2022; Member-fee info</span>
          <hr/>
          <i>2023/01/03</i>
          <ul style={{paddingInlineStart: '20px'}}>
            <li>Overall fixes</li>
            <li>Major training-lists update (styling & functionality), including:
              <br/>- Player-stats with filter- & sorting-options
              <br/>- Color-coding for trainings depending on training-group
            </li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.1 - Training-Improvements &#x2022; Coaches-Corner</span>
          <hr/>
          <i>2022/12/12</i>
          <ul style={{paddingInlineStart: '20px'}}>
            <li>Training improvements</li>
            <li>Coaches corner updates</li>
          </ul>

          <br/><br/>
          <span style={{fontWeight: 700}}>v1.0.0 - Initial release</span>
          <hr/>
          <i>2022/12/02</i>
          <ul style={{paddingInlineStart: '20px'}}>
            <li>Basic functionality</li>
          </ul>
        </div>
      }
    </>
  )
}