import React, {useState} from "react";
import UpcomingTrainingsShortlist from "./training/UpcomingTrainingsShortlist/";
import {Link} from "react-router-dom";
import wpDataService from "../services/wp-data-service";
import {toast} from "react-toastify";
import Loader from "./Loader";
import PlayerTrainingStatsInfo from "./training/training-components/PlayerTrainingStatsInfo";
import ClubStatsBox from "./club/ClubStatsBox";
import MonthlyFameInfoBox from "./training/training-components/MonthlyFameInfoBox";
import ClubDatesCalendar from "./club/ClubDatesCalendar";
import HomeAppInfoPopUp from "./HomeAppInfoPopUp";

function Home({userData, setUserWPData, userImageUrl, infoNotifications, userRoles}) {
  const [savingMemberFeeUserFeedback, setSavingMemberFeeUserFeedback] = useState(false);

  function getMemberTypeTitle(memberTypeSlug) {
    switch (memberTypeSlug) {
      case 'honourable':
        return 'Ehrenmitglied';
      case 'supporting':
        return 'Förderndes Mitglied';
      case 'idle':
        return 'Außerordentliches Mitglied';
      default:
        return 'Ordentliches Mitglied';
    }
  }


  const InfoBar = () => {

    const InfoNotifications = () => {
      if (Object.keys(infoNotifications).length === 0) {
        return '';
      }
      return (
        <>
          {infoNotifications.map((infoNotification, index) => (
            <div className={'info-message ' + infoNotification['info-type']}
                 key={index}
                 dangerouslySetInnerHTML={{__html: infoNotification.message}}
            />
          ))}
        </>
      );
    };

    const MemberFeeInfoMessage = () => {
      if (Object.keys(userData).length === 0 || userData.memberFeeData.member_fee_status !== 'outstanding') {
        return '';
      }
      let memberFeeStatusUserFeedback = userData.memberFeeData.member_fee_status_user_feedback && userData.memberFeeData.member_fee_status_user_feedback === 'on';
      if (memberFeeStatusUserFeedback) {
        return '';
      }

      let memberFeeName = userData.memberFeeData.fee_tag;
      return (
        <div className={'info-message member-fee-info-message'} onClick={() => {
          document.getElementById('finances').scrollIntoView()
        }}>
          {memberFeeName} ausstehend!
          <Link to="#finances" className="member-fee-info-message__link">Info ></Link>
        </div>
      );
    };

    return (
      <div className='info-messages-wrapper'>
        <InfoNotifications/>
        <MemberFeeInfoMessage/>
      </div>
    );
  }


  const setMemberFeeStatusUserFeedback = () => {
    setSavingMemberFeeUserFeedback(true);
    let userFeedback = userData.memberFeeData.member_fee_status_user_feedback === 'on' ? '' : 'on';

    const dataService = new wpDataService();
    dataService.setMemberFeeUserFeedback(userData.userID, userFeedback).then(
      (result) => {
        console.log('MGB-Rückmeldung wurde gespeichert!');
        console.log(result.data);

        setSavingMemberFeeUserFeedback(false);

        let newMemberFeeData = userData.memberFeeData;
        newMemberFeeData.member_fee_status_user_feedback = userFeedback;

        setUserWPData((current) => ({...current, memberFeeData: newMemberFeeData,}));

        toast.success("MGB-Rückmeldung wurde gespeichert!");
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
          toast.error(error.response.data.error);

          setSavingMemberFeeUserFeedback(false);
        } else {
          console.error(error);
          toast.error('Fehler beim Speichern deiner Rückmeldung.');
        }
      }
    );
  }

  const MemberFeeInfoBox = () => {
    const [memberFeeUserFeedbackBoxActive, setMemberFeeUserFeedbackBoxActive] = useState(false);
    if (Object.keys(userData).length === 0) {
      return '';
    }


    let memberFeeName = userData.memberFeeData.fee_name;
    let memberFeeStatus = userData.memberFeeData.member_fee_status;
    let memberFeeAmount = userData.memberFeeData.member_fee_amount;
    let outstandingFeeMessage = userData.memberFeeOutstandingMessage;
    let memberFeeStatusUserFeedback = userData.memberFeeData.member_fee_status_user_feedback && userData.memberFeeData.member_fee_status_user_feedback === 'on';

    let memberFeeStatusFormatted = '';
    switch (memberFeeStatus) {
      case 'outstanding':
        memberFeeStatusFormatted = 'ausstehend';
        break;
      case 'paid':
        memberFeeStatusFormatted = 'bezahlt';
        break;
      case 'exempt':
        memberFeeStatusFormatted = 'befreit';
        break;
      default:
        memberFeeStatusFormatted = '';
    }

    if (memberFeeStatus === 'outstanding' && memberFeeStatusUserFeedback) {
      memberFeeStatusFormatted = 'als bezahlt markiert'
    }

    return (
      <>
        <div
          className={'member-fee-info ' + memberFeeStatus + (memberFeeStatusUserFeedback ? ' paid-by-user' : '')}>

          {savingMemberFeeUserFeedback &&
            <div className="loader-wrapper">
              <Loader/>
            </div>
          }

          <div className="member-fee-info__fee-name">{memberFeeName}</div>
          <div className={'member-fee-info__member-status'}>
            {memberFeeAmount}{memberFeeAmount !== 0 ? ' € :' : ''} &nbsp; {memberFeeStatusFormatted}
          </div>

          {memberFeeStatus === 'outstanding' && !memberFeeStatusUserFeedback &&
            <div className="member-fee-info__content"
                 dangerouslySetInnerHTML={{__html: outstandingFeeMessage}}/>
          }

          {memberFeeStatus === 'outstanding' && memberFeeStatusUserFeedback &&
            <div className="member-fee-info__content">
              Warte auf Bestätigung durch Kassiere..
            </div>
          }


          {memberFeeStatus === 'outstanding' && !memberFeeStatusUserFeedback &&
            // <div className="member-fee-info__setUserFeedbackButton"
            //      onClick={() => setMemberFeeUserFeedbackBoxActive(current => !current)}>
            //     <>Mitgliedsbeitrag als bezahlt markieren ></>
            // </div>
            <div className="member-fee-info__setUserFeedbackButton"
                 onClick={setMemberFeeStatusUserFeedback}>
              <>Mitgliedsbeitrag als bezahlt markieren ></>
            </div>
          }
          {memberFeeStatus === 'outstanding' && memberFeeStatusUserFeedback &&
            <div className="member-fee-info__setUserFeedbackButton"
                 onClick={setMemberFeeStatusUserFeedback}>
              <>Noch nicht überwiesen? ></>
            </div>
          }

        </div>
        {memberFeeUserFeedbackBoxActive &&
          <div className="myaug-popup__wrapper">
            <div className="myaug-popup">
              <div className="close-popup"
                   onClick={() => setMemberFeeUserFeedbackBoxActive(current => !current)}>
                SCHLIESSEN
              </div>
              abschließen??

              <div className="aug-button member-fee-info__setUserFeedbackButton"
                   onClick={setMemberFeeStatusUserFeedback}>
                als bezahlt markieren
              </div>
            </div>
          </div>
        }
      </>
    );
  }


  return (
    <>
      <div className={"profil-bar"}>
        <div className={"profil-bar__image"}>
          <img src={userImageUrl} alt="Profil Foto"/>
        </div>
        <div className={"profil-bar__info"}>
          <div className={"profil-bar__name"}>
            <div className={"profil-bar__number"}>
              {userData.nick} {userData.jerseyNr}
            </div>
          </div>

            <div className={"profil-bar__roles"}>
              {Object.keys(userData).length !== 0 &&
                <div className={'member-role-tags'}>
                  {
                    userData.roles.map(memberRole => {
                      if (memberRole !== 'member' && memberRole !== 'administrator') {
                        let roleName = ''
                        switch (memberRole) {
                          case 'board-member':
                            roleName = 'Vorstand';
                            break;
                          case 'cashier':
                            roleName = 'Finanzen';
                            break;
                          case 'trainer':
                            roleName = 'Trainer'
                            break;
                          default:
                            roleName = '';
                        }
                        return (
                          <div key={userData.nick + userData.jerseyNr + memberRole}
                               className={'member-role-tag info-bar-tag'}>{roleName}</div>
                        );
                      }

                      return '';
                    })
                  }
                </div>
              }
            </div>

          <div className={"profil-bar__member-type"}>
            <div>{getMemberTypeTitle(userData.memberType)}</div>
          </div>
        </div>
      </div>

      <InfoBar/>

      {userData.appInfoPopUps.appProfile === false &&
        <HomeAppInfoPopUp playerName={userData.nick} info={'appProfile'} />
      }

      <main className="home">
        <Link className="home__heading-link" to="/training"><h2>Training</h2></Link>

        <br/>Bevorstehende Trainings:<br/>
        <UpcomingTrainingsShortlist userEmail={userData.email} userID={userData.userID} userRoles={userRoles}/>

        <br/>Trainingsrückblick:<br/>
        <MonthlyFameInfoBox/>
        <PlayerTrainingStatsInfo simple userData={userData}/>
        <Link className={'aug-button chevron-right wide'} to={'/training/stats'}>Season-Stats</Link>
        <Link className={'aug-button secondary chevron-right wide no-margin'} to={'/training/past-trainings'}>Vergangene
          Trainings</Link>
        <br/>
        <br/>

        <h2 id="finances">Finanzen</h2>
        <br/>
        <MemberFeeInfoBox/>
        <br/>
        <br/>

        <Link className="home__heading-link" to="/club"><h2>Verein</h2></Link>
        <br/>
        <div className="home__club-overview">
          Unter <Link to="/club">Verein</Link> findest du wichige Links, Infos zum Verein und die wichtigsten
          Kontaktadressen und Ansprechpersonen.
          <br/><br/>
          <h3>Termine</h3>
          <ClubDatesCalendar/>
          <br/>
          <ClubStatsBox/>
          <Link to="/club/members" className={'aug-button secondary chevron-right wide no-margin'}>
            Mitglieder-Liste anzeigen
          </Link>

          {Array.isArray(userRoles) && userRoles.includes('board-member') &&
            <>
              <br/>
              <Link to="/club/member-data" className={'aug-button board-members chevron-right wide no-margin'}>
                Mitglieder-Daten
              </Link>
            </>
          }
        </div>
        <br/>
        <br/>

      </main>
    </>
  );
}

export default Home;
