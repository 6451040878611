import React, {useEffect, useState} from "react";
import Select from "react-select";
import wpDataService from "../../services/wp-data-service";
import {Link} from "react-router-dom";
import Loader from "../Loader";

export default function PlayersStats({currentSeason}) {
  const [allSeasons, setAllSeasons] = useState(null);
  const [allSeasonsMap, setAllSeasonsMap] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [sortBy, setSortBy] = useState({value: 'total', label: 'Total'}); // percentage, total, name
  const [filterBy, setFilterBy] = useState(false); // percentage, total, name
  const [activeSeason, setActiveSeason] = useState(null);
  const activeSeasonSelect = activeSeason ? {value: activeSeason.ID, label: activeSeason.name} : null;

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getAllSeasons().then(
      (result) => {
        console.log('Received all seasons:');
        console.log(result);
        const allSeasonsMap = {};
        result.data.forEach(season => {
          allSeasonsMap[season.ID] = season;
        });
        setAllSeasons(result.data)
        setAllSeasonsMap(allSeasonsMap);
        setActiveSeason(result.data[0]);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
      }
    );
  }, [])

  useEffect(() => {
    setStatsData(null);
    if (activeSeason === null) {
      return;
    }

    const dataService = new wpDataService();
    dataService.getPlayersTrainingStats(activeSeason.ID).then(
      (result) => {
        console.log('HEEEREEEEE');
        console.log('Received player\'s training stats:');
        console.log(result);
        setStatsData(result.data)
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
      }
    );
  }, [activeSeason])

  const StatsHeader = () => {
    return (
      <div style={{display: 'flex', zIndex: 99,}}>
        <h2>Season-Stats</h2>
        <SeasonSelect/>
      </div>
    );
  }

  const SeasonSelect = () => {
    const options = allSeasons.map((season) => (
      {
        value: season.ID,
        label: season.name
      }
    ));

    return (
      <Select name={'select-season'}
              isMulti={false}
              options={options}
              defaultValue={activeSeasonSelect}
              className="aug-select"
              classNamePrefix="aug-select"
              isSearchable={false}
              isClearable={false}
              onChange={(e) => {
                setActiveSeason(allSeasonsMap[e.value])
              }}
              placeholder={'Saison...'}/>
    );
  }

  const SeasonOverview = () => {
    if (statsData && statsData.seasonStats && statsData.seasonStats.totalCount === 0) {
      return '';
    }
    const seasonStats = statsData.seasonStats;
    return (
      <div className={'season-stats'}>
        {/*<h3>Season Übersicht</h3>*/}
        <div className={'season-stats__trainings-list'}>
          <div className={'season-stats__trainings-list--item'}>
            <div>Trainings gesamt</div>
            <div>{seasonStats.totalCount}</div>
          </div>
          {Object.keys(seasonStats.trainingGroups).map(trainingGroupSlug => {
            const trainingGroup = seasonStats.trainingGroups[trainingGroupSlug];
            return (
              <div key={trainingGroup.slug} className={'season-stats__trainings-list--item'}
                   style={{color: trainingGroup.color, borderColor: trainingGroup.color}}>
                <div>{trainingGroup.name}</div>
                <div>{trainingGroup.trainings.length}</div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  if (allSeasons === null) {
    return (
      <main className={'training-stats'}>
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <br/>
        Lade Seasons
        <br/>
        <div className='loader-wrapper-relative'>
          <Loader/>
        </div>
      </main>
    );
  }


  if (statsData && statsData.playerStats && Object.keys(statsData.playerStats).length !== 0) {

    const getSortedAttendanceData = () => {
      let attData = Object.keys(statsData.playerStats).map((key) => statsData.playerStats[key]);
      let specificTrainingGroups = [];

      if (filterBy.length) {
        attData = attData.filter((element) => {
          let activeFilters = {};
          let elementIsWithinFilter = false;

          filterBy.forEach((filter) => {
            if (!activeFilters[filter.type]) {
              activeFilters[filter.type] = [];
            }

            if (filter.type === 'trainingGroup') {
              element.trainingGroups.forEach((trainingGroup) => {
                if (trainingGroup.slug === filter.value) {
                  elementIsWithinFilter = true;
                }
              })
              specificTrainingGroups.push(filter.value);
            } else if (element[filter.type] === filter.value) {
              elementIsWithinFilter = true;
            } else {
              elementIsWithinFilter = false;
            }
            activeFilters[filter.type].push(elementIsWithinFilter);
          })

          if (Object.keys(activeFilters).length > 1) {
            elementIsWithinFilter = true;
            Object.keys(activeFilters).forEach((activeFilterGroup) => {
              if (elementIsWithinFilter) {
                let elementIsWithinSingleFilter = false;
                Object.keys(activeFilters[activeFilterGroup]).forEach((filterGroup) => {
                  if (activeFilters[activeFilterGroup][filterGroup] === true) {
                    elementIsWithinSingleFilter = true;
                  }
                })

                elementIsWithinFilter = elementIsWithinSingleFilter;
              }
            })
          } else if (Object.keys(activeFilters).length === 1) {
            elementIsWithinFilter = false;
            let filterGroup = activeFilters[Object.keys(activeFilters)[0]];
            filterGroup.forEach((filter) => {
              if (!elementIsWithinFilter && filter) {
                elementIsWithinFilter = true;
              }
            })
          }

          return elementIsWithinFilter;
        });
      }


      // if(filterBy.filter(element => element.type === 'count')) {
      //
      // }

      attData.sort((a, b) => {
        return ((a.nick < b.nick) ? 1 : -1);
      });

      if (sortBy.value === 'name') {
        return attData.sort((a, b) => {
          return ((a.nick > b.nick) ? 1 : -1);
        });
      } else if (sortBy.value === 'percentage') {
        return attData.sort((a, b) => {
          let trainingPercentageA = (a.total_attended_trainings !== 0 && a.total_possible_trainings !== 0) ? a.total_attended_trainings / a.total_possible_trainings * 100 : 0;
          let trainingPercentageB = (b.total_attended_trainings !== 0 && b.total_possible_trainings !== 0) ? b.total_attended_trainings / b.total_possible_trainings * 100 : 0;
          if (specificTrainingGroups.length) {
            trainingPercentageA = 0;
            trainingPercentageB = 0;
            specificTrainingGroups.forEach(trainingGroupSlug => {
              // specificPossibleTrainings += statsData.seasonStats.trainingGroups[trainingGroupSlug].trainings.length;
              const trainingGroupID = statsData.seasonStats.trainingGroups[trainingGroupSlug].ID;
              trainingPercentageA += a.training_groups[trainingGroupID] && a.training_groups[trainingGroupID].total_attended_trainings !== 0 && a.training_groups[trainingGroupID].possible_trainings !== 0 ? a.training_groups[trainingGroupID].total_attended_trainings / a.training_groups[trainingGroupID].total_possible_trainings : 0;
              trainingPercentageB += b.training_groups[trainingGroupID] && b.training_groups[trainingGroupID].total_attended_trainings !== 0 && b.training_groups[trainingGroupID].possible_trainings !== 0 ? b.training_groups[trainingGroupID].total_attended_trainings / b.training_groups[trainingGroupID].total_possible_trainings : 0;
            });
          }
          return ((trainingPercentageA < trainingPercentageB) ? 1 : -1);
        });
      } else if (sortBy.value === 'total') {
        return attData.sort((a, b) => {
          let trainingCountA = a.total_attended_trainings;
          let trainingCountB = b.total_attended_trainings;

          if (specificTrainingGroups.length) {
            trainingCountA = 0;
            trainingCountB = 0;
            specificTrainingGroups.forEach(trainingGroupSlug => {
              // specificPossibleTrainings += statsData.seasonStats.trainingGroups[trainingGroupSlug].trainings.length;
              const trainingGroupID = statsData.seasonStats.trainingGroups[trainingGroupSlug].ID;
              trainingCountA += a.training_groups[trainingGroupID] && a.training_groups[trainingGroupID].total_attended_trainings ? a.training_groups[trainingGroupID].total_attended_trainings : 0;
              trainingCountB += b.training_groups[trainingGroupID] && b.training_groups[trainingGroupID].total_attended_trainings ? b.training_groups[trainingGroupID].total_attended_trainings : 0;
            });
          }

          return ((trainingCountA < trainingCountB) ? 1 : -1);
        });
      }
    }

    const FilterBar = () => {
      // [
      //   {value: 'indoor', label: 'Indoor', type: 'trainingGroup'},
      //   {value: 'saisonvorbereitung', label: 'Saisonvorbereitung', type: 'trainingGroup'},
      // ]
      const filterOptionsTrainingGroups = Object.keys(statsData.seasonStats.trainingGroups).map(trainingGroupSlug => {
        const trainingGroup = statsData.seasonStats.trainingGroups[trainingGroupSlug];
        return {
          value: trainingGroup.slug,
          label: trainingGroup.name,
          type: 'trainingGroup',
        }
      });

      const filterOptions = [
        // {
        //   label: 'Anzahl', options: [
        //     {value: 'last20', label: '20', type: 'count'}
        //   ]
        // },
        {
          label: 'Trainingsgruppen', options: filterOptionsTrainingGroups
        },
        {
          label: 'Geschlecht', options: [
            {value: 'm', label: 'm', type: 'gender'},
            {value: 'f', label: 'w', type: 'gender'},
          ]
        },
      ]
      const options = [
        {value: 'total', label: 'Total'},
        {value: 'percentage', label: 'Prozent'},
        {value: 'name', label: 'Name'}
      ]

      return (
        <div className={'training-stats__filter-bar'}>
          <div className={'training-stats__filter-bar--filter'}>
            <label htmlFor={'select-filter-by'}>Filter</label>

            <Select name={'select-filter-by'}
                    isMulti={true}
                    options={filterOptions}
                    defaultValue={filterBy}
                    className="aug-select"
                    classNamePrefix="aug-select"
                    isSearchable={false}
                    isClearable={true}
                    onChange={(event) => {
                      if (event !== null) {
                        setFilterBy(event);
                      }
                    }}
                    placeholder={'Filtern...'}/>
          </div>

          <div/>

          <div className={'training-stats__filter-bar--sort'}>
            <label htmlFor={'select-sort-by'}>Sortieren</label>

            <Select name={'select-sort-by'}
                    options={options}
                    defaultValue={sortBy}
                    className="aug-select"
                    classNamePrefix="aug-select"
                    isSearchable={false}
                    onChange={(event) => {
                      if (event !== null) {
                        setSortBy(event);
                      }
                    }}/>
          </div>
        </div>
      );
    }

    const getColor = (percentage) => {
      if (percentage >= 90) {
        return '#c5a135';
      } else if (percentage >= 75) {
        return '#337649';
      } else if (percentage >= 60) {
        return '#21502f';
      } else if (percentage >= 45) {
        return '#9d4a18';
      } else if (percentage >= 30) {
        return '#8e1b1e';
      } else if (percentage >= 1) {
        return '#5b191a';
      } else {
        return 'black';
      }
    }

    const getBackgroundGradientID = (percentage) => {
      if (percentage >= 90) {
        return 'goldGradient';
      } else if (percentage >= 75) {
        return 'greenGradient';
      } else if (percentage >= 60) {
        return 'greenGradient';
      } else if (percentage >= 45) {
        return 'orangeGradient';
      } else if (percentage >= 30) {
        return 'redGradient';
      } else if (percentage >= 1) {
        return 'redGradient';
      } else {
        return 'blackGradient';
      }
    }

    const PercentageBar = ({percentage, playerID}) => {
      return (
        <div className={'percentage-bar'}
             style={{width: '100%', minHeight: '6px', background: 'black', position: "relative"}}>
          <div className={'percentage-bar__inner'}
               style={{
                 width: '100%',
                 textAlign: 'center',
                 fontSize: '20px',
                 position: 'relative',
                 zIndex: 5,
               }}>
          </div>
          <div className={'percentage-bar__filling'}
               style={{
                 width: percentage + '%',
                 height: '100%',
                 background: getColor(percentage),
                 position: 'absolute',
                 top: 0,
                 zIndex: 1,
               }}/>
        </div>
      );
    };


    const TrainingStatsPlayersList = () => {
      let attendanceData = statsData.playerStats;
      attendanceData = getSortedAttendanceData();
      let specificTrainingGroups = [];

      if (filterBy !== false) {
        filterBy.forEach((filter) => {
          if (filter.type === 'trainingGroup') {
            specificTrainingGroups.push(filter.value);
          }
        })
      }

      const FilterResultsCount = () => (
        <div className={'training-stats__filter-results-count'}>
          <span style={{fontWeight: 500,}}>{Object.keys(attendanceData).length}</span> Ergebnisse
        </div>
      )

      return (
        <div className={'training-stats__players-list'}>
          <h3>Player Stats</h3>
          <FilterBar/>
          <FilterResultsCount/>
          {
            Object.keys(attendanceData).map((index) => {
                let playerID = attendanceData[index]['playerID'];
                const playerData = statsData.playerStats[playerID];

                let attendedTrainings = playerData.total_attended_trainings;
                let possibleTrainings = playerData.total_possible_trainings;
                let trainingAttendancePercentage = Math.round(attendedTrainings / possibleTrainings * 100);
                let lastFive = playerData.last_five;

                if (specificTrainingGroups.length) {
                  lastFive = false;
                  attendedTrainings = 0;
                  possibleTrainings = 0;
                  specificTrainingGroups.forEach(trainingGroupSlug => {
                    // specificPossibleTrainings += statsData.seasonStats.trainingGroups[trainingGroupSlug].trainings.length;
                    const trainingGroupID = statsData.seasonStats.trainingGroups[trainingGroupSlug].ID;
                    attendedTrainings += playerData.training_groups[trainingGroupID] && playerData.training_groups[trainingGroupID].total_attended_trainings ? playerData.training_groups[trainingGroupID].total_attended_trainings : 0;
                    possibleTrainings += playerData.training_groups[trainingGroupID] && playerData.training_groups[trainingGroupID].total_possible_trainings ? playerData.training_groups[trainingGroupID].total_possible_trainings : 0;
                  });

                  trainingAttendancePercentage = Math.round(attendedTrainings / possibleTrainings * 100);
                }

                return (
                  <div key={playerID}
                       className={'training-stats__players-list--player-box ' + getBackgroundGradientID(trainingAttendancePercentage)}
                       style={{borderLeft: '2px solid ' + getColor(trainingAttendancePercentage)}}>
                    <PercentageBar percentage={trainingAttendancePercentage} playerID={playerID}/>
                    <div className={'player-box__content'}>
                      <div className={'player-box__attendance-percentage'}>
                        {trainingAttendancePercentage}%
                      </div>
                      <div className={'player-box__name'}>
                        <div className={'player-box__nick'}>
                          {playerData.nick}
                        </div>
                        <div className={'player-box__number'}>
                          {playerData.jerseyNr}
                        </div>
                      </div>
                      <div className={'player-box__attendance-total'}>
                        <div>
                          {attendedTrainings} / {possibleTrainings}
                        </div>
                        {lastFive && (
                          <div className={'player-box__last-five'} >
                            {lastFive.map((lastFiveDot) => (
                              <div className={'player-box__last-five-dot' + (lastFiveDot === true ? ' true' : ' false')} />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            )
          }
        </div>
      );
    }


    return (
      <main className={'training-stats'}>
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <StatsHeader/>
        <SeasonOverview/>
        <TrainingStatsPlayersList/>
      </main>
    );
  }

  if (statsData && statsData.playerStats && Object.keys(statsData.playerStats).length === 0) {
    return (
      <main className={'training-stats'}>
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <StatsHeader/>
        <br/>
        Für diese Season sind noch keine Trainings-Statistiken vorhanden.
      </main>
    );
  }

  return (
    <main className={'training-stats'}>
      <Link className="back-to-link" to={'/training/'}>
        {'<'} Zurück zur Trainingsübersicht
      </Link>
      <StatsHeader/>
      <br/>
      <div className='loader-wrapper-relative'>
        <Loader/>
      </div>
    </main>
  );
}