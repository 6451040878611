import wpDataService from "../../services/wp-data-service";
import React, {useEffect, useState} from "react";
import AttendanceStatusButton from "./AttendanceStatusButton";
import {Link} from "react-router-dom";
import Loader from "../Loader";
import PlayerTrainingStatsInfo from "./training-components/PlayerTrainingStatsInfo";

function PastPlayerTrainings({userData}) {
  let userID = userData.userID;
  let userMail = userData.email;
  const [pastTrainingsData, setPastTrainingsData] = useState({});
  const [isLoadingTrainingData, setIsLoadingTrainingData] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getPastTrainingsForMember(userMail).then(
      (result) => {
        console.log('Received WordPress-data PAST TRAININGS FOR PLAYER:');
        console.log(result.data);
        if (result.data !== 'error') {
          setPastTrainingsData(result.data);
        }
        setIsLoadingTrainingData(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingTrainingData(false);
      }
    );
  }, [userMail, userID])

  if (isLoadingTrainingData) {
    return (
      <main>
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <h2>Vergangene Trainings</h2>
        <div className='loader-wrapper-relative'>
          <Loader/>
        </div>
      </main>
    )
  }

  if (Object.keys(pastTrainingsData).length === 0) {
    return (
      <main>
        <Link className="back-to-link" to={'/training/'}>
          {'<'} Zurück zur Trainingsübersicht
        </Link>
        <h2>Vergangene Trainings</h2>
        <br/>
        Keine Trainings gefunden.
      </main>
    )
  }


  let playerTrainingCounts = {
    attending: 0,
    absent: 0,
    total: 0,
  };
  pastTrainingsData.forEach((training) => {
    playerTrainingCounts.total++;
    if (Object.keys(training.attendanceConfirmation).some(key => key === (userID + ''))) {
      playerTrainingCounts[training.attendanceConfirmation[userID]] = playerTrainingCounts[training.attendanceConfirmation[userID]] + 1;
    } else if (Object.keys(training.attendance).some(key => key === (userID + ''))) {
      playerTrainingCounts[training.attendance[userID]] = playerTrainingCounts[training.attendance[userID]] + 1;
    }
  });

  let trainingsData = pastTrainingsData.reduce(
    (prevValue, currentValue, index) => {
      return {...prevValue, [currentValue.month]: {...prevValue[currentValue.month], [index]: currentValue}};
    },
    0
  );

  return (
    <main>
      <Link className="back-to-link" to={'/training/'}>
        {'<'} Zurück zur Trainingsübersicht
      </Link>
      <h2>Vergangene Trainings</h2>

      <PlayerTrainingStatsInfo userData={userData}/>
      <br/>

      <div className="training-list past-trainings-list">
        {
          Object.keys(trainingsData).map((trainingMonth) => (
            <div className="training-list__month-box" key={trainingMonth}>
              <div className="training-list__month-heading">
                {trainingMonth}
              </div>
              {
                Object.keys(trainingsData[trainingMonth]).map((trainingIndex) => {
                  let training = trainingsData[trainingMonth][trainingIndex];
                  let trainingUnitClassNames = 'training-list__training-unit';
                  if (Object.keys(training.attendanceConfirmation).some(key => key === (userID + ''))) {
                    trainingUnitClassNames += ' player-' + training.attendanceConfirmation[userID];
                  } else if (Object.keys(training.attendance).some(key => key === (userID + ''))) {
                    trainingUnitClassNames += ' player-' + training.attendance[userID];
                  } else {
                    return '';
                  }
                  trainingUnitClassNames += (training.trainingStatus.value === 'closed' ? ' closed' : '');

                  let trainingUnitStyle = {};
                  let trainingUnitTitleStyle = {};
                  if (training.trainingGroups.length > 0) {
                    let color = training.trainingGroups[0].groupColor;
                    trainingUnitStyle.borderColor = color;
                    trainingUnitTitleStyle.color = color;
                  }

                  return (
                    <div
                      className={trainingUnitClassNames}
                      key={training.ID}
                      style={trainingUnitStyle}>

                      <div className="training-list__content-column">
                        <div className="training-list__title-row">
                          <div
                            className="training-list__date">{training.weekday.substr(0, 2)}, {training.formattedDate.substr(0, 7)}
                          </div>
                          <div
                            className="training-list__title"
                            style={trainingUnitTitleStyle}>{training.data.post_title}</div>
                        </div>
                        <div className="training-list__time">
                          <div>{training.timeFrom} - {training.timeUntil}</div>
                        </div>
                        {training.location.length !== 0 &&
                          <div className="training-list__location">
                            <div>{training.location[0].name}</div>
                          </div>
                        }
                        <div className="training-list__groups">
                          {
                            training.trainingGroups.map((trainingGroup) => {
                              let trainingUnitTagStyle = {
                                color: trainingGroup.groupColor,
                                borderColor: trainingGroup.groupColor,
                              }
                              return <div className="training-list__group"
                                          key={trainingGroup.slug}
                                          style={trainingUnitTagStyle}>{trainingGroup.name}</div>
                            })
                          }
                        </div>
                      </div>

                      <AttendanceStatusButton training={training} userID={userID}/>
                    </div>
                  )
                })
              }
            </div>
          ))
        }
      </div>
    </main>
  )
}

export default PastPlayerTrainings;