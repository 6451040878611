import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop({scrollArea}) {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (scrollArea) {
      scrollArea.scrollTo(0, 0);
    }
  }, [pathname, scrollArea]);

  return null;
}
