export default function PercentageCircle({percentage, color}) {

  const getColor = (percentage) => {
    if (percentage >= 90) {
      return '#c5a135';
    } else if (percentage >= 75) {
      return '#337649';
    } else if (percentage >= 60) {
      return '#21502f';
    } else if (percentage >= 45) {
      return '#9d4a18';
    } else if (percentage >= 30) {
      return '#8e1b1e';
    } else if (percentage >= 1) {
      return '#5b191a';
    } else {
      return 'black';
    }
  }

  const getBackgroundGradientID = (percentage) => {
    if (percentage >= 90) {
      return 'goldGradient';
    } else if (percentage >= 75) {
      return 'greenGradient';
    } else if (percentage >= 60) {
      return 'greenGradient';
    } else if (percentage >= 45) {
      return 'orangeGradient';
    } else if (percentage >= 30) {
      return 'redGradient';
    } else if (percentage >= 1) {
      return 'redGradient';
    } else {
      return 'blackGradient';
    }
  }

  const BackgroundGradientDefs = () => {
    let startColor = 'rgba(0, 0, 0, 0.4)';

    return (
      <defs>
        <linearGradient id="goldGradient">
          <stop offset="0%" stopColor={startColor}/>
          <stop offset="80%" stopColor={'rgba(197, 161, 53, 0.37)'}/>
        </linearGradient>

        <linearGradient id="greenGradient">
          <stop offset="0%" stopColor={startColor}/>
          <stop offset="80%" stopColor={'rgba(51, 118, 73, 0.37)'}/>
        </linearGradient>

        <linearGradient id="orangeGradient">
          <stop offset="0%" stopColor={startColor}/>
          <stop offset="80%" stopColor={'rgba(157, 74, 24, 0.2)'}/>
        </linearGradient>

        <linearGradient id="redGradient">
          <stop offset="0%" stopColor={startColor}/>
          <stop offset="80%" stopColor={'rgba(127, 38, 36, 0.37)'}/>
        </linearGradient>

        <linearGradient id="greyGradient">
          <stop offset="0%" stopColor={startColor}/>
          <stop offset="80%" stopColor={'rgba(255, 255, 255, 0.1)'}/>
        </linearGradient>
      </defs>
    );
  }

  const cleanPercentage = (percentage) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
  };

  const Circle = ({color, percentage}) => {
    const r = 50;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
    return (
      <circle
        r={r}
        cx={60}
        cy={60}
        fill={"url(#" + getBackgroundGradientID(percentage) + ")"}
        stroke={strokePct !== circ ? color : ""} // remove color as 0% sets full circumference
        strokeWidth={"6px"}
        strokeDasharray={circ}
        strokeDashoffset={percentage ? strokePct : 0}
      ></circle>
    );
  };

  const Text = ({percentage}) => {
    return (
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={"25px"}
        fontWeight={'900'}
        stroke={'rgba(255,255,255, 0.1)'}
        strokeWidth={0}
        fill={'rgba(197, 161, 53, 0.74)'}
      >
        {percentage.toFixed(0)}%
      </text>
    );
  };


  const pct = cleanPercentage(percentage);
  return (
    <svg width={120} height={120}>
      <BackgroundGradientDefs/>
      <g transform={`rotate(-90 ${"60 60"})`}>
        <Circle color="black"/>
        <Circle color={getColor(pct)} percentage={pct}/>
      </g>
      <Text percentage={pct}/>
    </svg>
  );
};