import {Link} from "react-router-dom";
import SecondaryMenu from "./SecondaryMenu";
import PullToRefresh from "pulltorefreshjs";
import React, {useEffect, useRef, useState} from "react";
import {Squash as Hamburger} from 'hamburger-react';
import {Scrollbar} from 'react-scrollbars-custom';

function Header({Logout, userRoles}) {
  const [menuIsActive, setMenuIsActive] = useState(false);
  const menuScrollArea = useRef();

  const toggleMenu = () => {
    setMenuIsActive(!menuIsActive);
    menuScrollArea.current.scrollTo(0, 0);
  };

  let headerBarMenuWrapperClass = 'header-bar-menu-wrapper' + (menuIsActive ? ' active' : '');

  useEffect(initiatePullToRefresh, []);

  return (
    <>
      <header className="header-bar" id="header-bar">
        <Link to="/" className={"header-bar__title-logo"}>
          <h1>my.augärtner</h1>
        </Link>
        <Hamburger hideOutline={true}
                   toggled={menuIsActive}
                   toggle={toggleMenu}
                   color={"#c5a135"}
                   size={48}
                   distance="lg"
        />
      </header>

      <div className={headerBarMenuWrapperClass}>
        <Scrollbar ref={menuScrollArea}>

          <SecondaryMenu Logout={Logout} userRoles={userRoles} toggleMenu={toggleMenu}/>

        </Scrollbar>
      </div>
    </>
  );

  function initiatePullToRefresh() {
    PullToRefresh.init({
      mainElement: '#header-bar',
      triggerElement: '#header-bar',
      distThreshold: 100,
      distMax: 120,
      distReload: 80,
      distIgnore: 5,
      distResisted: 60,
      onRefresh: function () {
        window.location.reload();
      },
      shouldPullToRefresh: function () {
        return !this.mainElement.scrollTop;
      },
      instructionsPullToRefresh: 'Ziehen für App-Refresh',
      instructionsReleaseToRefresh: 'Loslassen für App-Refresh'
    });
  }
}

export default Header;
