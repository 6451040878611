import React, {useEffect, useState} from "react";
import wpDataService from "../../../services/wp-data-service";
import Loader from "../../Loader";
import PercentageCircle from "./PercentageCircle";
import {BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, LabelList} from 'recharts';
import {Scrollbar} from 'react-scrollbars-custom';

export default function PlayerTrainingStatsInfo({userData, simple = false}) {
  let userID = userData.userID;
  let userMail = userData.email;
  const currentSeason = userData.currentSeason;
  const [pastTrainingsData, setPastTrainingsData] = useState({});
  const [isLoadingTrainingData, setIsLoadingTrainingData] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getPastTrainingsForMember(userMail, currentSeason.ID).then(
      (result) => {
        console.log('Received WordPress-data PAST TRAININGS FOR PLAYER:');
        console.log(result.data);
        if (result.data !== 'error') {
          setPastTrainingsData(result.data);
        }
        setIsLoadingTrainingData(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingTrainingData(false);
      }
    );
  }, [userMail, userID, currentSeason])

  if (isLoadingTrainingData) {
    return (
      <div className={'player-training-stats-info'}>
        <div className='loader-wrapper-relative'>
          <Loader/>
        </div>
      </div>
    )
  }

  const getClassNamesForBackgroundGradient = (percentage) => {
    if (percentage >= 90) {
      return ' gold';
    } else if (percentage >= 75) {
      return ' green';
    } else if (percentage >= 60) {
      return ' green';
    } else if (percentage >= 45) {
      return ' orange';
    } else if (percentage >= 30) {
      return ' red';
    } else if (percentage >= 1) {
      return ' red';
    } else {
      return '';
    }
  }


  // Training counts
  let playerTrainingCounts = {
    attending: 0,
    absent: 0,
    total: 0,
  };

  let playerTrainingMonths = {};

  pastTrainingsData.forEach((training) => {
    playerTrainingCounts.total++;
    if (!playerTrainingMonths[training.month]) {
      playerTrainingMonths[training.month] = {
        attending: 0,
        absent: 0,
        total: 0,
        name: training.month.substr(0, 3),
      }
    }
    playerTrainingMonths[training.month].total = playerTrainingMonths[training.month].total + 1;

    if (Object.keys(training.attendanceConfirmation).some(key => key === (userID + ''))) {
      playerTrainingCounts[training.attendanceConfirmation[userID]] = playerTrainingCounts[training.attendanceConfirmation[userID]] + 1;
      playerTrainingMonths[training.month][training.attendanceConfirmation[userID]] = playerTrainingMonths[training.month][training.attendanceConfirmation[userID]] + 1;
    } else if (Object.keys(training.attendance).some(key => key === (userID + ''))) {
      playerTrainingCounts[training.attendance[userID]] = playerTrainingCounts[training.attendance[userID]] + 1;
      playerTrainingMonths[training.month][training.attendance[userID]] = playerTrainingMonths[training.month][training.attendance[userID]] + 1;
    }
  });


  // Bar chart
  let data = [];
  Object.keys(playerTrainingMonths).reverse().forEach((trainingMonth) => {
    data.push(playerTrainingMonths[trainingMonth]);
  });

  let percentage = Math.round(playerTrainingCounts.attending / playerTrainingCounts.total * 100);

  return (
    <>
      <div
        className={'player-training-stats-info' + getClassNamesForBackgroundGradient(percentage) + (simple ? ' simple' : '')}>

        <div className="player-training-stats-info__stats-text--title">

          <div className="player-training-stats-info__stats-text--title-left">
            Trainingsbeteiligung
          </div>

          <div className="player-training-stats-info__stats-text--title-right">
            Season {currentSeason.name}
          </div>
        </div>


        <div className="player-training-stats-info__stats-content">

          <div className="player-training-stats-info__circle">
            <PercentageCircle
              percentage={percentage}
              color={'red'}/>
          </div>

          <div className="player-training-stats-info__counts">
                        <span className="player-training-stats-info__count-attended">
                            {playerTrainingCounts.attending}
                        </span>
            <span className="player-training-stats-info__count-divider"> / </span>
            <span className="player-training-stats-info__count-total">
                            {playerTrainingCounts.total}
                        </span>

            <div className="player-training-stats-info__count-description">
              Trainings
            </div>
          </div>
        </div>

        {!simple && (Object.keys(playerTrainingMonths).length > 0) &&
          <div style={{width: '100%'}}>
            <div style={{
              fontSize: '10px',
              letterSpacing: 2,
              textTransform: 'uppercase',
              margin: '0 10px 10px'
            }}>
              Monatsübersicht:
            </div>
            <Scrollbar style={{minHeight: '130px'}}>
              <ResponsiveContainer width="100%" height="100%" minHeight={'120px'}>
                <BarChart barSize={50} width={Object.keys(playerTrainingMonths).length * 100}
                          height={120}
                          data={data}>
                  <CartesianGrid strokeDasharray="1 9"/>
                  <XAxis dataKey="name"/>

                  <defs>
                    <linearGradient id="gradient" gradientTransform="rotate(90)">
                      <stop offset="5%" stopColor="#c5a135"/>
                      <stop offset="85%" stopColor="#c28e00"/>
                    </linearGradient>
                  </defs>
                  <Bar dataKey="attending" stackId={'a'} fill="url(#gradient)">
                    <LabelList dataKey="attending" position="center" angle="0" fill={'white'}
                               formatter={(value) => {
                                 return value !== 0 ? value : '';
                               }}/>
                  </Bar>
                  <Bar dataKey="absent" stackId={'a'} fill="black">
                    <LabelList dataKey="absent" position="center" angle="0"
                               formatter={(value) => {
                                 return value !== 0 ? value : '';
                               }}/>
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Scrollbar>
          </div>
        }


      </div>


    </>
  )
}