import React, {useEffect, useState} from "react";
import wpDataService from "../../services/wp-data-service";
import Loader from "../Loader";

export default function ClubDatesCalendar({clubDatesData = []}) {
  const [clubDates, setClubDates] = useState(clubDatesData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getClubDates().then(
      (result) => {
        console.log('Received ClubDates:', result);

        setClubDates(result.data);
        setIsLoading(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoading(false);
      }
    );
  }, [])

  if (isLoading) {
    return (
      <div className={'loader-wrapper-relative'}>
        <Loader/>
      </div>
    );
  }

  if (!isLoading && !clubDates.length) {
    return (
      <>Keine kommenden Termine...</>
    );
  }

  return (
    <div className={'club-dates-calendar'}>
      {
        clubDates.map(clubDate => (
          <div className={'club-dates-calendar__event'} key={clubDate.dateFrom}>
            <div className={'club-dates-calendar__event-date'}>
              <div>
                {clubDate.dateFrom}
              </div>
              {clubDate.dateTo &&
                <>
                  <div>-</div>
                  <div>{clubDate.dateTo}</div>
                </>
              }
            </div>
            <div className={'club-dates-calendar__event-name'}>{clubDate.name}</div>
          </div>
        ))
      }
    </div>
  )
}