import {GoogleOAuthProvider, GoogleLogin} from "@react-oauth/google";
import React from "react";
import userAuthenticationService from "../services/user-authentication";
import {toast} from "react-toastify";

export default function AppLogin({setUserData}) {
  const authenticationService = new userAuthenticationService();

  return (
    <GoogleOAuthProvider clientId="758162827711-f3qsim29spegg14r53pijeh5ahtaa6s3.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={authorizeUser}
        onError={handleLoginError}
        auto_select
        useOneTap
        theme={'outline'}
      />
      <br/>
    </GoogleOAuthProvider>
  );

  function authorizeUser(credentialResponse) {
    console.log(credentialResponse);

    authenticationService.getMemberData(credentialResponse).then(
      (result) => {
        console.log('Received member-data:', result);
        setUserData(result.data);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        handleLoginError(error)
      }
    );
  }

  function handleLoginError(error) {
    console.log('error:', error);
    if (error.details) {
      toast.error(<>Login fehlgeschlagen!<br/>DETAILS: {error.details}.</>);
    } else if (error.error) {
      toast.error(<>Login fehlgeschlagen!<br/>DETAILS: {error.error}.</>);
    } else if (error.data) {
      toast.error(<>Login fehlgeschlagen!<br/>DETAILS: {error.data}.</>);
    } else {
      toast.error(<>Login fehlgeschlagen!</>);
    }
  }
}


//GET https://people.googleapis.com/v1/people:listDirectoryPeople

// const loginSuccess = (response) => {
//   console.log(response);
//
//   setUserGoogleData({
//     name: response.profileObj.name,
//     email: response.profileObj.email,
//     imageUrl: response.profileObj.imageUrl,
//   });
//   setUserData(true);
// }
//
// <button className="aug-button" onClick={renderProps.onClick}
//         disabled={renderProps.disabled}>
//   Einwerfen
// </button>
//
// const loginFailed = (response) => {
//   console.warn(response);
//
//   if (response.details) {
//     toast.error(<>Login fehlgeschlagen!<br/>DETAILS: {response.details}.</>);
//   } else if (response.error) {
//     toast.error(<>Login fehlgeschlagen!<br/>DETAILS: {response.error}.</>);
//   } else {
//     toast.error(<>Login fehlgeschlagen!</>);
//   }
// }


// const LoginButton = () => {
//   const login = useGoogleLogin({
//     onSuccess: authorizeUser,
//     onError: handleLoginError,
//     hosted_domain: 'augaertner-ultimate.at'
//   });
//
//   useGoogleOneTapLogin({
//     onSuccess: authorizeUser,
//     onError: handleLoginError,
//     auto_select: true,
//     hosted_domain: 'augaertner-ultimate.at'
//   });
//
//   return (
//     <div className={'aug-button'} onClick={() => login()}>
//       EINWERFEN
//     </div>
//   );
// }