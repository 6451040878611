import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import wpDataService from "../../../services/wp-data-service";
import Loader from "../../Loader";
import {UpcomingTrainingShort} from "./UpcomingTrainingShort";

function UpcomingTrainingsShortlist({userID, userEmail, userRoles}) {
  const [trainingData, setTrainingData] = useState({});
  const [isLoadingTrainingData, setIsLoadingTrainingData] = useState(true);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getUpcomingTrainingsShortlistForMember(userEmail).then(
      (result) => {
        console.log('Received WordPress-data TRAINING FOR PLAYER:');
        console.log(result.data);
        if (result.data !== 'error') {
          setTrainingData(result.data);
        }
        setIsLoadingTrainingData(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingTrainingData(false);
      }
    );
  }, [userEmail]);

  const TrainingUnitDummy = () => (
    <div className="training-list__training-unit loading">
      <div className="training-list__content-column">
        <div className="training-list__title-row">
          <div className="training-list__date">-</div>
          <div className="training-list__title">-</div>
        </div>
        <div className="training-list__time">
          <div>--:--</div>
        </div>
        <div className="training-list__location">
          <div>-</div>
        </div>
        <div className="training-list__groups">
          <div className="training-list__group"></div>
        </div>
      </div>
      <div className="training-list__attendance-column">
        <div className="training-list__attendance-column--loading">
          <div className="loader"></div>
        </div>
        <div className="training-list__attendance-column--icon pending"></div>
      </div>
    </div>
  );

  if (isLoadingTrainingData) {
    return (
      <div className="training-list training-shortlist">
        <div className="training-shortlist__loading-dummies">
          <div className="loader-wrapper">
            <Loader/>
          </div>

          <TrainingUnitDummy/>
          <TrainingUnitDummy/>
          <TrainingUnitDummy/>
        </div>
        {Array.isArray(userRoles) && userRoles.includes('trainer') &&
          <>
            <br/><br/>
            <Link className="aug-button secondary chevron-right coaches"
                  to="/coaches/">
              Coaches Corner
            </Link>
          </>
        }
      </div>
    )
  }

  if (!Object.keys(trainingData).length) {
    return (
      <div className="training-list training-shortlist">
        Keine anstehenden Trainings..

        {Array.isArray(userRoles) && userRoles.includes('trainer') &&
          <>
            <br/><br/>
            <Link className="aug-button secondary chevron-right coaches"
                  to="/coaches/">
              Coaches Corner
            </Link>
          </>
        }
      </div>
    )
  }

  if (trainingData.error && trainingData.error === 'player not active') {
    return (
      <div className="training-list training-shortlist">
        Dein Teamstatus ist zur zeit auf "inaktiv" gesetzt. Um an Trainings teilnehmen zu können, melde dich beim
        Trainer-Team.
      </div>
    )
  }

  // const getDateString = (day) => {
  //     let dd = String(day.getDate()).padStart(2, '0');
  //     let mm = String(day.getMonth() + 1).padStart(2, '0'); //January is 0!
  //     let yyyy = day.getFullYear();
  //     return yyyy + '-' + mm + '-' + dd;
  // }



  return (
    <div className="training-list training-shortlist">
      {
        trainingData.map((training, index) => {
          if (index >= 3) {
            return '';
          }

          return <UpcomingTrainingShort key={training.ID} userID={userID} training={training}/>;
        })
      }
      <Link className="aug-button chevron-right" to="/training/registration">ZU- / ABSAGE</Link>
      {Array.isArray(userRoles) && userRoles.includes('trainer') &&
        <Link className="aug-button secondary chevron-right coaches"
              to="/coaches/">
          Coaches Corner
        </Link>
      }
    </div>
  )
}

export default UpcomingTrainingsShortlist;
