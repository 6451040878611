import wpDataService from "../services/wp-data-service";
import Loader from "./Loader";
import AppLogout from "../LoginScreen/AppLogout";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Header from "./generic-components/Header";
import {Scrollbar} from "react-scrollbars-custom";
import ScrollToTop from "./ScrollToTop";
import Home from "./Home";
import Club from "./club/Club";
import Quicklinks from "./club/Quicklinks";
import Members from "./club/Members";
import Training from "./training/Training";
import TrainingRegistration from "./training/TrainingRegistration";
import PastPlayerTrainings from "./training/PastPlayerTrainings";
import PlayersStats from "./training/PlayersStats";
import CoachesCorner from "./training/trainers/CoachesCorner";
import UpcomingTrainings from "./training/trainers/UpcomingTrainings";
import PastTrainings from "./training/trainers/PastTrainings";
import PersonalData from "./PersonalData";
import {ToastContainer} from "react-toastify";
import NavBar from "./generic-components/NavBar";
import React, {useRef, useState} from "react";
import MemberData from "./club/MemberData";
import ClubContacts from "./club/ClubContacts";

export default function MainApp({userData, setUserData}) {
  const userLoggedIn = true;
  // const [userGoogleData, setUserGoogleData] = useState({});
  const [isLoadingUserData, setIsLoadingUserData] = useState(false);
  const [userWPData, setUserWPData] = useState(userData);
  const [clubWPData, setClubWPData] = useState({});
  const [infoNotificationsWPData, setInfoNotificationsWPData] = useState({});
  const userRoles = userData.roles;

  const scrollArea = useRef();


  /**
   * MEMBER AREA:
   **/
  if (!isLoadingUserData) { // Object.keys(userWPData).length === 0
    setIsLoadingUserData(true);
    const dataService = new wpDataService();
    dataService.getInfoNotifications().then(
      (result) => {
        console.log('Received WordPress-data info-notifications:');
        console.log(result);
        setInfoNotificationsWPData(result.data);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
      }
    );

    dataService.getClubInfoData().then(
      (result) => {
        console.log('Received WordPress-data CLUB:');
        console.log(result);
        setClubWPData(result.data);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
      }
    );
  }


  if (Object.keys(userWPData).length === 0) {
    return (
      <div className='app-content logging-in'>
        <Loader/>
      </div>
    );
  }


  const headerProps = {
    Logout: AppLogout,
    userRoles,
  }

  let appContentClassName = 'app-content';
  appContentClassName += (userLoggedIn ? ' logged-in' : ' login-screen')

  return (
    <div className={appContentClassName}>
      <Router>
        <Header {...headerProps} />

        <Scrollbar ref={scrollArea}>
          <ScrollToTop scrollArea={scrollArea.current}/>
          <Routes>
            <Route path="/"
                   element={<Home userData={userWPData}
                                  setUserWPData={setUserWPData}
                                  userImageUrl={userData.picture}
                                  infoNotifications={infoNotificationsWPData}
                                  userRoles={userRoles}
                   />}
            />

            <Route path="/club" element={<Club clubData={clubWPData} userRoles={userRoles}/>}/>
            <Route path="/club/contacts" element={<ClubContacts clubData={clubWPData} userRoles={userRoles}/>}/>
            <Route path="/club/quicklinks" element={<Quicklinks clubData={clubWPData}/>}/>
            <Route path="/club/members" element={<Members/>}/>
            <Route path="/club/member-data" element={<MemberData userRoles={userRoles}/>}/>

            <Route path="/training" element={<Training userData={userWPData}
                                                       userRoles={userRoles}/>}
            />
            <Route path="/training/registration"
                   element={<TrainingRegistration userEmail={userWPData.email}
                                                  userID={userWPData.userID}/>}
            />
            <Route path="/training/past-trainings"
                   element={<PastPlayerTrainings userData={userWPData}/>}/>
            />
            <Route path="/training/stats" element={<PlayersStats currentSeason={userWPData.currentSeason}/>}/>
            <Route path="/coaches/" element={<CoachesCorner userRoles={userRoles}/>}/>
            <Route path="/coaches/upcoming-trainings"
                   element={<UpcomingTrainings userRoles={userRoles}/>}/>
            <Route path="/coaches/past-trainings" element={<PastTrainings userRoles={userRoles}/>}/>

            <Route path="/data" element={<PersonalData userData={userWPData} setUserData={setUserData}/>}/>
          </Routes>
        </Scrollbar>

        <ToastContainer
          position="bottom-left"
          autoClose={4500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme={'dark'}
        />
        <NavBar/>
      </Router>
    </div>
  );
}