// ** React
import {useEffect, useState} from "react";

//** Helpers
import wpDataService from "../../services/wp-data-service";

// ** Components
import Loader from "../Loader";

export default function PersonalDataPrivacyTexts() {
  const [privacyTexts, setPrivacyTexts] = useState(null);

  useEffect(() => {
    if (privacyTexts === null) {
      const dataService = new wpDataService();
      dataService.getClubMemberPrivacyTexts().then(
        (result) => {
          console.log('Received privacy-texts:');
          console.log(result);
          setPrivacyTexts(result.data);
        },
        (error) => {
          if (error.response) {
            console.warn(error.response.data);
          } else {
            console.error(error);
          }
        }
      );
    }
  }, [privacyTexts])

  if (privacyTexts) {
    return (
      <>
        <p>Du hast folgenden Datenschutzbestimmungen bei deiner Anmeldung im Verein zugestimmt:</p>
        {privacyTexts.map((privacyText, index) => {
          return(
            <div key={index} style={{background: 'rgba(0,0,0,0.2)', margin: '0 0 40px', padding: '15px'}}>
              <h4>{privacyText.heading.de}</h4>
              <div dangerouslySetInnerHTML={{__html: privacyText.text.de}} />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Loader/>
    </>
  );
}