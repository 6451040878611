import Loader from "../Loader";

function AttendanceStatusButton({training, userID}) {
  let trainingAttendance = '';
  if (Object.keys(training.attendanceConfirmation).some(key => key === (userID + '')) && training.attendanceConfirmation[userID] !== '') {
    trainingAttendance = training.attendanceConfirmation[userID];
  } else if (Object.keys(training.attendance).some(key => key === (userID + '')) && training.attendance[userID] !== '') {
    trainingAttendance = training.attendance[userID];
  }

  if (trainingAttendance) {
    let attendanceClass = trainingAttendance;
    return (
      <div className={'training-list__attendance-column ' + attendanceClass}>
        <div className="training-list__attendance-column--loading">
          <Loader/>
        </div>
        <div className={'training-list__attendance-column--icon ' + attendanceClass}/>
      </div>
    );
  }

  return (
    <div className="training-list__attendance-column">
      <div className="training-list__attendance-column--loading">
        <Loader/>
      </div>
      <div className="training-list__attendance-column--icon"/>
    </div>
  );
}

export default AttendanceStatusButton;