import Navigation from "./Navigation";

function NavBar() {

  return (
    <footer className="nav-bar">
      <Navigation/>
    </footer>
  );
}

export default NavBar;
