import Loader from "../Loader";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import wpDataService from "../../services/wp-data-service";
import {toast} from "react-toastify";

export default function PersonalDataAppProfileOptions({userData, setUserData}) {
  const [isLoadingAppProfileOptions, setIsLoadingAppProfileOptions] = useState(true);
  const [isSavingAppProfileOptions, setIsSavingAppProfileOptions] = useState(false);
  const [appProfileOptions, setAppProfileOptions] = useState({
    slogan: '',
    phone: false,
    mail: false,
    augMail: false,
    fullName: true,
  });
  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getMemberAppProfileOptions(userData.userID).then(
      (result) => {
        console.log('Received app-pofile options:');
        console.log(result);
        if (result.data === '') {

        } else {
          setAppProfileOptions(result.data);
        }
        setIsLoadingAppProfileOptions(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingAppProfileOptions(false);
      }
    );
  }, [userData.userID])

  const saveAppProfileOptions = () => {
    setIsSavingAppProfileOptions(true);
    const dataService = new wpDataService();
    dataService.setMemberAppProfileOptions(userData.userID, appProfileOptions).then(
      (result) => {
        console.log('Saved app-profile options:');
        console.log(result);
        setIsSavingAppProfileOptions(false);
        setUserData((current) => {
          const newAppInfoPopUps = {
            ...current.appInfoPopUps,
            appProfile: true,
          };
          return ({
            ...current,
            appInfoPopUps: newAppInfoPopUps,
          })
        })
        toast.success('App-Profil Einstellungen wurden gespeichert!');
        if (userData.appInfoPopUps.appProfile === false) {
          window.location.reload();
        }
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        toast.error('Fehler beim Speichern der Einstellungen!');
        setIsSavingAppProfileOptions(false);
      }
    );
  }
  if (isLoadingAppProfileOptions) {
    return (
      <Loader/>
    );
  }

  return (
    <div style={{position: 'relative'}}>
      {isSavingAppProfileOptions &&
        <div className={'loader-wrapper'}>
          <Loader/>
        </div>
      }
      <p>
        Lege fest, welche Daten in der Mitgliederliste für andere angezeigt werden sollen.
        <br/>
        <span className={'personal-data__label-description'}>
          (Nur in der App - nicht öffentlich zugänglich!)
        </span>
      </p>
      <ul style={{listStyle: 'none', padding: 0,}}>
        <li>
          <label htmlFor={'fullName'}>
            <input id={'fullName'} name={'fullName'} type={'checkbox'}
                   checked={appProfileOptions.fullName}
                   onChange={() => setAppProfileOption('fullName')}/> &nbsp;Vollen Namen anzeigen
            <div className={'personal-data__label-description'}>
              Setze diese Option, wenn du deinen vollen Namen in der <Link
              to={'/club/members'}>Mitgliederliste</Link> teilen möchtest.
            </div>
          </label>
        </li>
        <li>
          <label htmlFor={'augMail'}>
            <input id={'augMail'} name={'augMail'} type={'checkbox'}
                   checked={appProfileOptions.augMail}
                   onChange={() => setAppProfileOption('augMail')}/> &nbsp;Augärtner E-Mail anzeigen
            <div className={'personal-data__label-description'}>
              Setze diese Option, wenn du gelegentlich deine {userData.email}-Inbox überprüfst. Falls du unsere
              E-Mail-Funktion nicht nutzt, lass diese Checkbox leer.
            </div>
          </label>
        </li>
        <li>
          <label htmlFor={'mail'}>
            <input id={'mail'} name={'mail'} type={'checkbox'} checked={appProfileOptions.mail}
                   onChange={() => setAppProfileOption('mail')}/> &nbsp;Private E-Mail anzeigen
            <div className={'personal-data__label-description'}>
              Setze diese Option, wenn du deine E-Mail in der <Link to={'/club/members'}>Mitgliederliste</Link> teilen
              möchtest.
            </div>
          </label>
        </li>
        <li>
          <label htmlFor={'phone'}>
            <input id={'phone'} name={'phone'} type={'checkbox'}
                   checked={appProfileOptions.phone}
                   onChange={() => setAppProfileOption('phone')}/> &nbsp;Private Telefonnummer anzeigen
            <div className={'personal-data__label-description'}>
              Setze diese Option, wenn du deine Telefonnummer in der <Link
              to={'/club/members'}>Mitgliederliste</Link> teilen möchtest.
            </div>
          </label>
        </li>
        {/*<li>*/}
        {/*  <label htmlFor={'discord'}><input name={'discord'} type={'text'}/> Deine Discord-ID</label>*/}
        {/*</li>*/}
        <li>
          <label htmlFor={'slogan'}>Platz für deine Worte:</label>
          <textarea id={'slogan'} name={'slogan'} value={appProfileOptions.slogan}
                    onChange={(event) => setAppProfileSlogan(event.target.value)}/>
        </li>
      </ul>
      <div className={'aug-button'} onClick={saveAppProfileOptions}>App-Profil speichern</div>
    </div>
  );


  function setAppProfileOption(key) {
    setAppProfileOptions((current) => ({
      ...current,
      [key]: !current[key]
    }))
  }

  function setAppProfileSlogan(slogan) {
    setAppProfileOptions((current) => ({
      ...current,
      slogan: slogan
    }))
  }
}