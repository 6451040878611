import axios from 'axios' ;

export default class userAuthenticationService {
  constructor() {
    console.log('Starting user-authentication-service...');

    let site_url;
    switch (window.location.hostname) {
      case 'localhost':
        site_url = 'https://augaertner.test';
        break;
      case 'my.aug.onl':
        site_url = 'https://augaertner-ultimate.at';
        break;
      default:
        site_url = 'https://augaertner-ultimate.at';
    }
    this.api_url = site_url + '/wp-json/myaugaertner/v1';
    console.log('Location-URL is: ' + window.location.hostname);
    console.log('API-URL is: ' + this.api_url);


    // avoid a warning
    axios.interceptors.request.use(function (config) {
      const {headers = {}} = config || {};
      if (headers['User-Agent']) {
        delete config.headers['User-Agent'];
      }

      return config;
    })
  }

  getMemberData(credentials) {
    return axios.post(`${this.api_url}/getMemberData/`, credentials);
  }
}


