import React from "react";

export default function ClubContacts({clubData, userRoles}) {
  return (
    <main className={'club-contacts'}>
      <h3>Kontaktadressen</h3>
      <div dangerouslySetInnerHTML={{__html: clubData.importantContactsInfo}}/>

      <br/>
      <h3>Ansprechpersonen</h3>
      <div className="club__contacts">
        {
          clubData.importantContacts.map(contactGroup => {
            return (

              <div className="club__contact-group" key={contactGroup.groupName}>
                <div className="club__contact-group-name">
                  {contactGroup.groupName}
                </div>

                {contactGroup.contact.map(contact => {
                  return (
                    <div className="club__contact" key={contact.member.ID}>
                      <div className="club__contact-description">
                        {contact.description}
                      </div>
                      <div>
                        {contact.member.nickname} {contact.member.user_lastname}
                      </div>
                      <div>
                        <a href={'https://mail.google.com/mail/u/0/?to=' + contact.member.user_email + '&fs=1&tf=cm'}
                           target="blank">
                          {contact.member.user_email}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        }

      </div>
    </main>
  )
}