import ClubStatsBox from "./ClubStatsBox";
import {Link} from "react-router-dom";
import React from "react";
import ClubDatesCalendar from "./ClubDatesCalendar";

function Club({clubData, userRoles}) {
  if (Object.keys(clubData).length === 0) {
    return 'Loading';
  }

  return (
    <main className="club">
      <h2>Verein</h2>
      {clubData.clubDates !== null &&
        <>
          <h3>Termine</h3>
          <ClubDatesCalendar clubDatesData={clubData.clubDates}/>
          <br/>
        </>
      }

      <h3>Ansprechpersonen & Kontakte</h3>
      Hier findest du Infos zu unserem Vorstand & Trainerteam und alle nötigen Kontakte:<br/><br/>
      <Link to="/club/contacts" className={'aug-button chevron-right wide no-margin'}>
        Kontakte
      </Link>

      <br/>
      <h3>Dokumente & Links</h3>
      <Link to="/club/quicklinks" className={'aug-button chevron-right wide no-margin'}>
        Dokumente & Links
      </Link>

      <br/>
      <h3>Mitglieder</h3>
      <ClubStatsBox/>
      <Link to="/club/members" className={'aug-button secondary chevron-right wide no-margin'}>Mitglieder-Liste
        anzeigen</Link>

      {Array.isArray(userRoles) && userRoles.includes('board-member') &&
        <>
          <br/>
          <Link to="/club/member-data" className={'aug-button board-members chevron-right wide no-margin'}>
            Mitglieder-Daten
          </Link>
        </>
      }
    </main>
  );
}

export default Club;
