import React, {useEffect, useState} from "react";
import wpDataService from "../../services/wp-data-service";
import Loader from "../Loader";
import MemberProfile from "./MemberProfile";
import Select from "react-select";

const options = [
  {
    label: 'Mitgliedschaft', options: [
      {value: 'full', label: 'Ordentlich', type: 'membershipType'},
      {value: 'honourable', label: 'Ehrenmgl.', type: 'membershipType'},
      {value: 'supporting', label: 'Fördernd', type: 'membershipType'},
      {value: 'idle', label: 'Außerord.', type: 'membershipType'}
    ]
  },
  {
    label: 'Team-Status', options: [
      {value: 'active', label: 'aktiv', type: 'teamStatus'},
      {value: 'inactive', label: 'inaktiv', type: 'teamStatus'},
    ]
  },
  {
    label: 'Positionen', options: [
      {value: 'trainer', label: 'Trainer', type: 'memberRole'},
      {value: 'cashier', label: 'Finanzen', type: 'memberRole'},
      {value: 'board-member', label: 'Vorstand', type: 'memberRole'},
    ]
  },
]

function Members() {
  const [memberList, setMemberList] = useState({});
  const [isLoadingMemberList, setIsLoadingMemberList] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filterBy, setFilterBy] = useState(false);

  useEffect(() => {
    const dataService = new wpDataService();
    dataService.getMembersList().then(
      (result) => {
        console.log('Received WordPress-data Members-list:');
        console.log(result);

        setMemberList(result.data);
        setIsLoadingMemberList(false);
      },
      (error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else {
          console.error(error);
        }
        setIsLoadingMemberList(false);
      }
    );
  }, [])

  if (isLoadingMemberList) {
    return (
      <main>
        <h2>Mitglieder</h2>
        <div className='loader-wrapper-relative'>
          <Loader/>
        </div>
      </main>
    )
  }

  if (!isLoadingMemberList && !Object.keys(memberList).length) {
    return (
      <main>
        <h2>Mitglieder</h2>
        <br/>
        Keine Daten gefunden..
      </main>
    )
  }

  const handleMemberSearchOnChange = (e) => {

    e.preventDefault();
    setSearchInput(e.target.value);
  };

  let filteredMemberList = memberList;
  if (searchInput.length > 0) {
    filteredMemberList = memberList.filter((member) => {
      // debugger
      const firstLetterSearchString = searchInput[0].toLowerCase();
      const firstLetterPlayerName = member.nick.toLowerCase()[0];
      const firstLetterPlayerNumber = member.jerseyNr[0];
      const matchesName = (firstLetterSearchString === firstLetterPlayerName) && member.nick.toLowerCase().match(searchInput.toLowerCase());
      const matchesNumber = (firstLetterSearchString === firstLetterPlayerNumber) && member.jerseyNr.match(searchInput);
      return matchesName || matchesNumber;
    });
  }

  if (filterBy) {
    filteredMemberList = filteredMemberList.filter((member) => {
      let isWithinFilter = true;
      filterBy.forEach((filter) => {
        if (!isWithinFilter) {return}

        if (filter.type === 'membershipType') {
          if (member.memberType !== filter.value) {
            isWithinFilter = false;
          }
        }

        if (filter.type === 'teamStatus') {
          if (member.teamStatus.value !== filter.value) {
            isWithinFilter = false;
          }
        }

        if (filter.type === 'memberRole') {
          let fitsRole = false;
          member.roles.forEach((memberRole) => {
            if (fitsRole) {return}
            if (memberRole === filter.value) {
              fitsRole = true;
            }
          })

          isWithinFilter = isWithinFilter && fitsRole;
        }
      })

      return isWithinFilter;
    })
  }

  const MemberListCounter = ({memberListCount}) => {
    if (memberListCount === 0) {
      return (
        <div className={'member-list__members-count'}>Keine Ergebnisse..</div>
      )
    } else {
      return (
        <div className={'member-list__members-count'}>{memberListCount} Mitglieder</div>
      )
    }
  }


  let previousLetter = '';
  return (
    <main className="member-list">
      <h2>Mitglieder</h2>

      <div className={'member-list__header-bar'}>
        <input className={'member-list__search-bar'} type={'text'} placeholder={'Suchen... (Name/ Nummer)'}
               onChange={handleMemberSearchOnChange}/>
        <Select name={'select-sort-by'}
                options={options}
                isMulti={true}
                defaultValue={filterBy}
                className="aug-select"
                classNamePrefix="aug-select"
                isSearchable={false}
                isClearable={true}
                onChange={(event) => {
                  if (event !== null) {
                    setFilterBy(event);
                  }
                }}
                placeholder={'Filtern...'}/>
      </div>

      <MemberListCounter memberListCount={filteredMemberList.length} />

      {
        filteredMemberList.map((member) => {
          const currentLetter = member.nick[0];
          let isNewLetter = false;
          if (previousLetter !== currentLetter) {
            isNewLetter = true;
          }
          previousLetter = currentLetter;
          return (
            <React.Fragment key={member.nick + member.jerseyNr}>
              {isNewLetter &&
                <div className={'member-list__letter-row'}>{currentLetter}</div>
              }
              <MemberProfile member={member}/>
            </React.Fragment>
          );
        })
      }
    </main>
  );
}

export default Members;
