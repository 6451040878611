import React, {useState, useEffect} from "react";

export default function TrainingDeadlineCountdown({training}) {
  let deadlineDate = new Date(training.registrationDeadline.dateString + ' ' + training.registrationDeadline.timeString);
  let deadlineCounterStartDate = new Date(training.registrationDeadline.dateString + ' ' + training.registrationDeadline.timeString);
  const [currentCountdownCount, setCurrentCountdownCount] = useState('');
  deadlineCounterStartDate.setDate(deadlineDate.getDate() - 1);

  useEffect(() => {
    setInterval(function () {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = deadlineDate.getTime() - now;

      // Time calculations for days, hours, minutes and seconds
      // let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = ('0' + Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
      let minutes = ('0' + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).slice(-2);
      let seconds = ('0' + Math.floor((distance % (1000 * 60)) / 1000)).slice(-2);

      setCurrentCountdownCount(hours + ':' + minutes + ':' + seconds + '');
    }, 1000);
    // eslint-disable-next-line
  }, []);

  const today = new Date();
  if (today >= deadlineCounterStartDate && today <= deadlineDate && training.trainingStatus.value !== 'closed') {
    return (
      <div id="countdown-box" className="training-list__countdown-box">
        <span className="training-list__countdown-box--text">Anmeldefrist endet in:</span> <span
        className="training-list__countdown-box--counter">{currentCountdownCount}</span>
      </div>
    )
  }
  return '';
}
